import React, { useRef, useEffect, useState } from "react";
import { Avatar, Button, Checkbox, Dropdown, Empty, Image, Input, InputNumber, Row, Select, Modal, Col } from "antd";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import { Content } from "antd/es/layout/layout";
import AddLista from "../componentes/GeneralComponents/ModListDeContactos";
import dayjs from "dayjs";

import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import Eliminar from "../Img/Iconos/eliminar.svg";
import Imag from "../Img/no-image.png";
import CloseIcon from '@mui/icons-material/Close';

import ChatCard from "../componentes/Chats/ChatCard";
import Modalchat from "../componentes/GeneralComponents/ModalDetalle"
import ModalSelectContact from "../componentes/GeneralComponents/ModalDetalle"

import "./styles/Chats.scss";

import { UseModal } from "../hooks/UseModal";
import { Loader } from "../componentes/GeneralComponents/Loader";

import {
  chats_request,
  getReferenceOfChat,
  sendMessage_request,
  deleteChat_request
} from "../services/chats_services";

import { addReceta_request } from "../services/recetas_services";
import { getCIIEDiagnosticos_request, getMedicamentos_request } from "../services/consultas_services";
import MuiAlert from "@mui/material/Alert";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import { Add, ArrowBack, Contacts, MoreVert, QuestionAnswer, Search } from "@mui/icons-material";
import Pusher from 'pusher-js';
import { useLocation } from "react-router-dom";
import { ListItemDecorator } from "@mui/joy";
import { CircularProgress } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import Campos from "../componentes/GeneralComponents/campos";
import { Label, LeyendaError } from "../componentes/GeneralComponents/Formularios2Grids";
import Autosuggest from "react-autosuggest";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import { useDispatch, useSelector } from "react-redux";
import { badges } from "../redux/badges";
import { showSnackbar } from "../redux/snackbarSlice";
import { getAuthFirebase_request } from "../services/notificaciones_services";
import MessageCard from "../componentes/Chats/message_card"
import ReactGA from "react-ga4";
import BottomBarChat from "../componentes/Chats/bottom_bar_chat";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

import MessagesView from "../componentes/Chats/messages_view";
import useChatManager from "../hooks/useChatManager";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../storage/db";
import useContactosData from '../hooks/Query/useContactosData';
import { chars, delayCode } from "../utils/utils";
import LoaderCircle from "../componentes/GeneralComponents/LoaderCircle";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const pusher = new Pusher('0632eb2b2c205c095d85', {
  cluster: 'us3',
  enableLogging: false,
  encrypted: false,

},
  false);

export default function Chats() {
  const dispatch = useDispatch();
  const location = useLocation();

  const userInfo = useSelector((state) => state.userInfo.usuario);
  const data = useSelector((state) => state.userInfo.profesiones);
  let CedulasMedico = [];
  data.forEach(cedula => {
    CedulasMedico.push({ label: `${cedula.Cedula} - ${cedula.Profesion}`, value: cedula.Cedula })
  });

  // variable del metodo de modal
  const [isOpenChatModal, OpenChatModal, CloseChatModal] = UseModal(false);
  const [IsOpenSelectContacto, OpenSelectContacto, ClosedSelectContacto] = UseModal(false);

  const [EstatusModalEliminarChat, setEstatusModalEliminarChat] =
    useState(false);

  const [contactList, setContactList] = useState([]);
  const [contactListFilter, setContactListFilter] = useState([]);

  const [DatosContacto, setDatosContacto] = useState({ idUsuario: "", id: "", nombre: "", imagen: "", tipoUsuario: "" });

  // const [CargarChats, setchats] = useState([]);
  const [CargarChatsFilter, setchatsFilter] = useState([]);

  const [loader, setLoader] = useState(true);
  const [loaderSendMessage, setLoaderSendMessage] = useState(false);
  const [loaderDetalle, setLoaderDetalle] = useState(false);
  const [loaderSearch, setLoaderSearch] = useState(false);
  const [loaderChat, setLoaderChat] = useState(true);

  // ###### GRABACION DE AUDIO ######
  const [isRecording, setIsRecording] = useState(false);
  // ################################

  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [messageToSend, setMessageToSend] = useState("");
  const [messageToSendTemp, setMessageToSendTemp] = useState(""); // TODO para que guardar un temp???
  // const [messages, setMessages] = useState([]);

  const [messagesTemp, setMessagesTemp] = useState([]);
  const [idcontact, setIdContacto] = useState("");
  const [idreferencia, setReferencia] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [TipoM, setTipoM] = useState("");
  const [FilePreview, setFilePreview] = useState("");

  // Vista de imagen en el chat
  const [UrlImagePreview, setUrlImagePreview] = useState("");

  const [ChatOption, setChatOption] = useState(1);
  const [FromNewView, setFromNewView] = useState(false);

  const [Medicamentos, setMedicamentos] = useState([]);
  const [MedicamentosFilter, setMedicamentosFilter] = useState([]);

  const [Cedula, setCedula] = useState(CedulasMedico.length > 0 ? CedulasMedico[0].value : "");
  const [CostoReceta, setCostoReceta] = useState(0);
  const [Recomendaciones, setRecomendaciones] = useState("");
  const [Indicaciones, setIndicaciones] = useState("");

  const [Medicamento, setMedicamento] = useState("");
  const [Cantidad, setCantidad] = useState(1);
  const [Dosis, setDosis] = useState("");
  const [ListaMedicamentos, setListaMedicamentos] = useState([]);

  const [PalabraClave, setPalabraClave] = useState("");
  const [ListaDiagnosticos, setListaDiagnosticos] = useState([]);
  const [DiagnosticosSeleccionados, setDiagnosticosSeleccionados] = useState([]);
  const [DiagnosticosSeleccionadosMostrados, setDiagnosticosSeleccionadosMostrados] = useState([]);
  const [OtrosDiagnosticos, setOtrosDiagnosticos] = useState("");

  const [MedcSelection, setMedcSelection] = useState({});
  const [MedcSelectionDos, setMedcSelectionDos] = useState({});

  const [CheckFirma, setCheckFirma] = useState(true);
  const [CheckEnviar, setCheckEnviar] = useState(true);
  const [CheckIndicaciones, setCheckIndicaciones] = useState(true);
  const [CheckDiagnostico, setCheckDiagnostico] = useState(true);

  const [ShowViewMessages, setShowViewMessages] = useState(false);
  const [SelectedChat, setSelectedChat] = useState(null);

  const [pusherDatosTemp, setpusherDatosTemp] = useState({ referencia: "", canal: "" });

  const [FileToSend, setFileToSend] = useState({ name: "", url: "", file: "", size: 0, formato: "" });

  const [TabsChat, setTabsChat] = useState(0);

  const [MensajeCedula, setMensajeCedula] = useState("true");
  const [MensajeListaMedicamentos, setMensajeListaMedicamentos] = useState("true");
  const [MensajeMedicamento, setMensajeMedicamento] = useState("true");
  const [MensajeDosis, setMensajeDosis] = useState("true");

  // MESNAJE DIAGNOSTICO
  const [MensajeDiagnosticoCIIE, setMensajeDiagnosticoCIIE] = useState("true");
  const [MensajeOtrosDiagnosticos, setMensajeOtrosDiagnosticos] = useState("true");
  const [MensajePalabraClave, setMensajePalabraClave] = useState("true");

  const { isLoadingChats, GetConversations, AddMessage, DeleteConversation, GetConversation, LoadMessagesFromConversation } = useChatManager();

  const lastMessageRef = useRef(null);

  let items = [
    DatosContacto.tipoUsuario !== 1 && {
      key: '1',
      label: <li onClick={() => {
        sessionStorage.setItem("idContactoExpediente", DatosContacto.id);
        sessionStorage.setItem("nombreContactoExpediente", DatosContacto.nombre);
        sessionStorage.setItem("imagenContactoExpediente", DatosContacto.imagen);
        window.open(`/#/app/ExpedientesNuevoDetalle`, "_blank");
      }}>Expediente</li>,
    },
    DatosContacto.tipoUsuario !== 1 && {
      key: '2',
      label: <li onClick={() => {
        setChatOption(2);
      }}>Nueva receta</li>,
    },
    // {
    //   key: '3',
    //   label: <li onClick={() => {
    //     confirm();
    //   }}>Eliminar chat</li>,
    // },

  ]

  const contactos = useContactosData();

  const chats = useLiveQuery(
    async () => {
      const data = await db.conversations.toArray();
      return data.sort((a, b) => dayjs(b.fecha).valueOf() - dayjs(a.fecha).valueOf());
    },
    [],
    []
  )

  const messages = useLiveQuery(
    () => db.mensajes.where('Referencia').equals(idreferencia).toArray(),
    [idreferencia],
    []
  )

  const handleFileSelect = (event) => {
    setMessageToSend("");
    const file = event.target.files[0];
    let formato = file.type.split("/");

    // Validar el tamaño del archivo (5 MB = 5 * 1024 * 1024 bytes)
    if (file.size > 5000000) {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("El archivo es demasiado grande. Debe ser menor o igual a 5 MB.");
      return;
    }

    if (formato[1] === 'pdf') {
      // setFileToSend({ name: event.target.files[0].name, url: URL.createObjectURL(event.target.files[0]), file: event.target.files[0], size: event.target.files[0].size, formato: formato });
      sendMessage("1", file);
    } else if (['jpg', 'jpeg', 'png'].includes(formato[1])) {
      setFileToSend({ name: event.target.files[0].name, url: URL.createObjectURL(event.target.files[0]), file: event.target.files[0], size: event.target.files[0].size, formato: formato });
      setFilePreview(URL.createObjectURL(event.target.files[0]));
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Formato de archivo no permitido.");
    }
  };
  // Posicionar el scroll al ultimo mensaje
  const chatContainerRef = useRef(null);
  const chatContainerDivididoRef = useRef(null);

  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    chatContainerDivididoRef.current.scrollTop = chatContainerDivididoRef.current.scrollHeight;
  };

  const onDownload = () => {
    fetch(UrlImagePreview)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  function replaceURLWithHTMLLinks(text) {
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    let arrayText = text.split(" ");
    let texto = "";

    arrayText.forEach(element => {
      if (exp.test(element)) {
        texto += <a href="element">{element}</a>;
      } else {
        if (!!texto) {
          texto += " "
          texto += element;
        } else {
          texto += element;
          texto += " "
        }

      }
    });

    return <div>{texto}</div>;

  }

  useEffect(() => {
    // Llamar a la función scrollToBottom cada vez que se agregue un nuevo mensaje
    // scrollToBottom();
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (contactos.data && contactos.data.length > 0) {
      const contactosYgiaFilter = contactos.data.filter((contacto) => contacto.YgiaUser === 'Con Ygia');

      let contactosYgia = contactosYgiaFilter.map(element => {
        return {
          usuario: `${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno}`,
          imagen: `${!!element.Imagen_url ? element.Imagen_url : ""}`,
          id: `${element.IdContacto}`,
          idUsuario: `${element.IdPaciente}`
        }
      });

      setContactListFilter(contactosYgia);
      setContactList(contactosYgia);
    }
    else {
      setContactListFilter([]);
      setContactList([]);
    }
  }, [contactos.data]);

  async function getCIIEDiagnosticos() {
    setLoaderSearch(true);
    if (!!PalabraClave) {
      let response = await getCIIEDiagnosticos_request(PalabraClave);

      if (response.ok) {
        let list = [];
        if (!!response.data[0]) {
          response.data.forEach((diagnostico, index) => {
            list.push({ key: index, value: diagnostico, label: diagnostico })
          });
          setListaDiagnosticos(list);
        } else {
          setListaDiagnosticos([]);
          setOpen(true);
          setTypeMessage("warning");
          setMessage("Sin resultados");
        }
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {

    }
    setLoaderSearch(false);
    return;
  }

  const chatSolicitud = async (idpaciente, tipo) => {
    setLoaderChat(true);

    pusher.connect();
    const channel = pusher.subscribe(idpaciente.Channel);
    setpusherDatosTemp({ canal: idpaciente.Channel, referencia: idpaciente.Referencia });

    try {
      let mensajesTemp = messages;

      if (tipo !== undefined) {
        let mensajeNuevo = {
          Channel: idpaciente.Channel,
          Emisor: idpaciente.Emisor,
          // IdPaciente: idpaciente.IdPaciente,
          IdUsuario: idpaciente.IdUsuario,
          IdUsuario2: idpaciente.IdUsuario2,
          Referencia: idpaciente.Referencia,
          Multimedia: idpaciente.Multimedia !== undefined ? idpaciente.Multimedia : null,
          MultimediaUrl: idpaciente.MultimediaUrl !== undefined ? idpaciente.MultimediaUrl : null,
          TipoMensaje: idpaciente.TipoMensaje,
          Visto: idpaciente.Visto,
          created_at: idpaciente.created_at,
          fecha: idpaciente.fecha,
          id: idpaciente.id,
          message: idpaciente.message,
          updated_at: idpaciente.updated_at,
        }

        const chat = {
          Channel: idpaciente.Channel,
          IdContacto: DatosContacto.id,
          IdPaciente: idpaciente.IdUsuario2,
          IdUsuario: idpaciente.IdUsuario,
          IdUsuario2: idpaciente.IdUsuario2,
          Imagen_Url: DatosContacto.imagen,
          MensajesSinVer: 0,
          Referencia: idpaciente.Referencia,
          Status: 1,
          TipoMensaje: idpaciente.TipoMensaje,
          TipoUsuario: 3,//<<<<
          Usuario: DatosContacto.nombre,
          Visto: 0,
          created_at: idpaciente.created_at,
          fecha: idpaciente.fecha,
          id: idpaciente.id,
          message: idpaciente.message,
          status_chat: 1,
          updated_at: idpaciente.updated_at
        }
        if (!mensajesTemp.some(elemento => elemento.id === mensajeNuevo.id)) {
          AddMessage(mensajeNuevo)
        }
      }

      channel.bind('MensajeChat', (data) => {
        let mensaje = {
          Channel: data.message.Channel,
          Emisor: data.message.Emisor,
          // IdPaciente: data.message.IdPaciente,
          IdUsuario: data.message.IdUsuario,
          IdUsuario2: data.message.IdUsuario2,
          Referencia: data.message.Referencia,
          Multimedia: data.message.Multimedia !== undefined ? data.message.Multimedia : null,
          MultimediaUrl: data.message.MultimediaUrl !== undefined ? data.message.MultimediaUrl : null,
          TipoMensaje: data.message.TipoMensaje,
          Visto: data.message.Visto,
          created_at: data.message.created_at,
          fecha: data.message.fecha,
          id: data.message.id,
          message: data.message.message,
          updated_at: data.message.updated_at,
        }

        if (!mensajesTemp.some(elemento => elemento.id === mensaje.id)) {
          AddMessage(mensaje);
        }

        setLoaderSendMessage(false);
        return;
      });
    } catch (error) {
      console.log(error);
    }
    setReferencia('');
    if (tipo !== undefined) {
      setIdContacto(DatosContacto.id);
      setReferencia(idpaciente.Referencia);
    } else {
      setDatosContacto({ nombre: idpaciente.Usuario, imagen: idpaciente.Imagen_Url, id: idpaciente.IdContacto, idUsuario: idpaciente.IdPaciente, tipoUsuario: idpaciente.TipoUsuario });
      setIdContacto(idpaciente.IdContacto);

      let timeStamp = idpaciente.lastSync ? idpaciente.lastSync : dayjs().subtract(2, 'year').valueOf();

      await LoadMessagesFromConversation(idpaciente.Referencia, timeStamp);

      setReferencia(idpaciente.Referencia);

      delayCode(1000);
      setLoaderChat(false);
      // scrollToBottom();
    }
    getMedicamentos();
  };

  async function newChat(idContacto) {
    setReferencia('');

    const conversation = await GetConversation(idContacto);

    if (conversation) {
      setReferencia(conversation.Referencia);
      setpusherDatosTemp({ referencia: conversation.Referencia, canal: conversation.Channel });
    }
    else {
      let response = await getReferenceOfChat(idContacto);

      if (response.ok) {
        setpusherDatosTemp({ referencia: response.data.Referencia, canal: response.data.Canal });
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    }

  }

  async function addReceta() {
    setLoaderDetalle(true);
    if (!!Cedula && ListaMedicamentos.length > 0) {
      let diagnosticos_temp = []
      if (DiagnosticosSeleccionadosMostrados.length > 0) {
        DiagnosticosSeleccionadosMostrados.forEach(element => {
          diagnosticos_temp.push(element.diagnostico);
        });
      }

      let data = new FormData();
      data.append("Costo", CostoReceta);
      data.append("Cedula", Cedula);
      data.append("IdContacto", DatosContacto.id);
      data.append("Tipo", "0");
      data.append("Recomendaciones", Recomendaciones);
      data.append("Indicaciones", Indicaciones);
      data.append("Diagnostico", diagnosticos_temp.toString());
      data.append("Firma", CheckFirma ? 0 : 1);
      data.append("Enviar", CheckEnviar ? 0 : 1);
      data.append("MostrarIndicaciones", CheckIndicaciones ? 1 : 0);
      data.append("MostrarDiagnostico", CheckDiagnostico ? 1 : 0);
      ListaMedicamentos.forEach((element, index) => {
        data.append(`Concepto[${index}][Descripcion]`, element.Descripcion);
        data.append(`Concepto[${index}][Concepto]`, element.Concepto);
        data.append(`Concepto[${index}][Cantidad]`, element.Cantidad);
      });
      let response = await addReceta_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Receta creada");
        cleanInputsAdd();
        if (FromNewView) {
          setFromNewView(false);
          CloseChatModal(true);
        }
        setChatOption(1);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Cedula) {
        setMensajeCedula("false");
      }
      if (ListaMedicamentos.length === 0) {
        setMensajeListaMedicamentos("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }

    setLoaderDetalle(false);
    return;
  }

  async function getMedicamentos() {
    let response = await getMedicamentos_request();
    if (response.ok) {
      let medi_temp = [];
      response.data.forEach(medi => {
        medi_temp.push({ Numero: medi.id, Opcion: `${medi.nombreActivo} - ${medi.id}` })
      });
      setMedicamentos(medi_temp);
      setMedicamentosFilter(medi_temp);
    }
  }

  async function deleteChat() {
    CloseChatModal();
    setShowViewMessages(false);
    setLoader(true);

    let response = await deleteChat_request(idreferencia);

    if (response.ok) {
      dispatch(showSnackbar({
        show: true,
        text: "Chat eliminado",
        type: "success",
      }));
      await DeleteConversation(idreferencia);
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
    setLoader(false);
  }

  const onSuggestionsFetchRequested2 = ({ value }) => {
    setMedicamentos(FiltrarMedicamentos(value));
  };

  const FiltrarMedicamentos = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLenght = inputValue.Lenght;

    var filtrado = MedicamentosFilter.filter((medicamento) => {
      var textoCompleto = medicamento.Opcion;

      if (
        textoCompleto
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(inputValue)
      ) {
        return medicamento;
      }
    });
    return inputLenght === 0 ? [] : filtrado;
  };

  const onSuggestionsClearRequested2 = () => {
    setMedicamentos([]);
  };

  const getSuggestionValueDos = (suggestion) => {
    return `${suggestion.Opcion} `;
  };

  const renderSuggestionDos = (suggestion) => (
    <div className="sugerencia" onClick={() => { setMedcSelectionDos(suggestion); }}>
      {`${suggestion.Opcion}`}
    </div>
  );

  const onChangeDos = (e, { newValue }) => {
    if (newValue.length > 0) {
      setMensajeMedicamento("true");
    }

    setMedicamento(newValue);
  };

  const inputPropsDos = {
    placeholder: "Medicamento",
    value: Medicamento,
    onChange: (e, newValue) => {
      onChangeDos(e, newValue);
    },
  };

  const eventEnterDos = (e) => {
    if (e.key == "Enter") {
      var split = e.target.value.split("-");
      var medicamento = {
        Opcion: split[0].trim(),
        // Numero: split[1].trim(),
      };

      setMedcSelection(medicamento);
    }
  };

  // RECETA-MEDICAMENTOS
  function addMedicamento() {
    if (!!Medicamento && !!Dosis && !!Cantidad) {

      let arrayMedi = Medicamento.split("-");
      let medicamento_temp = arrayMedi[0];
      let id_temp = arrayMedi[1];
      let array = ListaMedicamentos;

      if (array.length > 0) {
        array.push({
          index: ListaMedicamentos[ListaMedicamentos.length - 1].index + 1,
          Id: id_temp !== undefined ? id_temp : "",
          Concepto: medicamento_temp,
          Cantidad: Cantidad,
          Descripcion: Dosis
        });
      } else {
        array.push({
          index: 1,
          Id: id_temp !== undefined ? id_temp : "",
          Concepto: medicamento_temp,
          Cantidad: Cantidad,
          Descripcion: Dosis
        });
      }

      setListaMedicamentos(array);

      setMedicamento("");
      setCantidad(1);
      setDosis("");
    } else {
      if (!!!Medicamento) {
        setMensajeMedicamento("false");
      }
      if (!!!Dosis) {
        setMensajeDosis("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }

    return;
  }

  function eliminarMedicamento(posicion) {
    let array = ListaMedicamentos;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => { return element.index !== posicion; });

    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        Concepto: element.Concepto,
        Cantidad: element.Cantidad,
        Descripcion: element.Descripcion
      });
    });

    setListaMedicamentos(arrayTres);
  }

  function addDiagnosticoCIIE() {
    if (DiagnosticosSeleccionados.length > 0) {
      DiagnosticosSeleccionados.forEach((diagnostico, index) => {
        let array = DiagnosticosSeleccionadosMostrados;
        if (array.length > 0) {
          array.push({
            index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
            diagnostico: diagnostico
          });

          setDiagnosticosSeleccionadosMostrados(array);
        } else {
          array.push({
            index: 1,
            diagnostico: diagnostico
          });

          setDiagnosticosSeleccionadosMostrados(array);
        }
      });

      setDiagnosticosSeleccionados([]);
    } else {
      if (DiagnosticosSeleccionados.length === 0) {
        setMensajeDiagnosticoCIIE("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }

    return;
  }

  function addDiagnosticoOtro() {
    if (!!OtrosDiagnosticos) {
      let array = DiagnosticosSeleccionadosMostrados;
      if (array.length > 0) {
        array.push({
          index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
          diagnostico: OtrosDiagnosticos
        });
      } else {
        array.push({
          index: 1,
          diagnostico: OtrosDiagnosticos
        });
      }

      setDiagnosticosSeleccionadosMostrados(array);
      setOtrosDiagnosticos("");
    } else {
      if (!!!OtrosDiagnosticos) {
        setMensajeOtrosDiagnosticos("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }

    return;
  }

  function eliminarDiagnostico(posicion) {
    let array = DiagnosticosSeleccionadosMostrados;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => { return element.index !== posicion; });
    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        diagnostico: element.diagnostico
      });
    });

    setDiagnosticosSeleccionadosMostrados(arrayTres);
  }

  function cleanInputsAdd() {
    setCedula("");
    setCostoReceta(0);
    setRecomendaciones("");
    setIndicaciones("");
    setMedicamento("");
    setDosis("");
    setListaMedicamentos([]);
    setCheckFirma(true);
    setCheckEnviar(true);
    setPalabraClave("");
    setOtrosDiagnosticos("");
    setDiagnosticosSeleccionados([]);
    setDiagnosticosSeleccionadosMostrados([]);
  }

  function filterContacts(text) {
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];

    contactList.forEach((contacto) => {
      if (
        contacto.usuario
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(contacto);
      }
    });

    setContactListFilter(filter);
  };

  ///////////////////////////////////////////

  async function sendMessage(tipo, archivo) {
    setLoaderSendMessage(true);
    let data = new FormData();
    let response;
    setMessageToSend("");

    switch (tipo) {
      case "0":
        if (!!messageToSendTemp.trim()) {
          let data = new FormData();
          data.append("TipoMensaje", tipo);
          data.append("mensaje", messageToSendTemp);
          response = await sendMessage_request(DatosContacto.id, data);

          if (response.ok) {
            document.getElementById("ChatFile").value = "";
            setMessageToSendTemp("");
            if (pusherDatosTemp.canal === false || pusherDatosTemp.canal === null) {
              chatSolicitud(response.data.Respuesta, 0);
            }
            else {
              AddMessage({ ...response.data.Respuesta, Emisor: 1 })
            }
          } else {
            setMessageToSend(messageToSendTemp);
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
            setMessageToSendTemp("");
          }
        } else {
          setOpen(true);
          setTypeMessage("warning");
          setMessage("No es posible enviar un mensaje vacio");
        }
        break;
      case "1":
      case "2":
        if (tipo === "1") {
          data.append("TipoMensaje", tipo);
          data.append("Multimedia", archivo, archivo.name);
        } else {
          data.append("TipoMensaje", tipo);
          data.append("Multimedia", FileToSend.file, FileToSend.name);
        }
        response = await sendMessage_request(DatosContacto.id, data);

        if (response.ok) {
          if (pusherDatosTemp.canal === false || pusherDatosTemp.canal === null) {
            chatSolicitud(response.data.Respuesta, 0);
          }
          else {
            AddMessage({ ...response.data.Respuesta, Emisor: 1 })
          }
          setFilePreview("")
          document.getElementById("ChatFile").value = "";

        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
        break;
      case "3":
        const audioToSend = await convertWebMtoMP4(archivo);

        const fecha = new Date();
        const año = fecha.getFullYear();
        const mes = String(fecha.getMonth() + 1).padStart(2, '0');
        const dia = String(fecha.getDate()).padStart(2, '0');
        const horas = String(fecha.getHours()).padStart(2, '0');
        const minutos = String(fecha.getMinutes()).padStart(2, '0');
        const segundos = String(fecha.getSeconds()).padStart(2, '0');

        const fecha_archivo = `${año}-${mes}-${dia}_at_${horas}_${minutos}_${segundos}`;
        data.append("TipoMensaje", tipo);
        data.append("Multimedia", audioToSend, `ygia_${fecha_archivo}.mp4`);

        response = await sendMessage_request(DatosContacto.id, data);

        if (response.ok) {
          if (pusherDatosTemp.canal === false || pusherDatosTemp.canal === null) {
            chatSolicitud(response.data.Respuesta, 0);
          } else {
            AddMessage({ ...response.data.Respuesta, Emisor: 1 })
          }
          setFilePreview("")
          document.getElementById("ChatFile").value = "";
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
        break;
    }
    setLoaderSendMessage(false);

  }

  // Función para convertir WebM a MP4
  const convertWebMtoMP4 = async (webmBlob) => {
    const ffmpeg = new FFmpeg(); // Crear una instancia de FFmpeg
    await ffmpeg.load(); // Cargar FFmpeg

    // Convertir el Blob de WebM a un archivo que FFmpeg pueda usar
    const webmData = await fetchFile(webmBlob);

    // Escribir el archivo WebM en el sistema de archivos de FFmpeg
    await ffmpeg.writeFile("input.webm", webmData);

    // Ejecutar el comando de FFmpeg para convertir WebM a MP4
    await ffmpeg.exec(["-i", "input.webm", "-c", "copy", "output.mp4"]);

    // Leer el archivo MP4 convertido
    const mp4Data = await ffmpeg.readFile("output.mp4");

    // Convertir el archivo MP4 a un Blob
    const mp4Blob = new Blob([mp4Data], { type: "video/mp4" });

    // Verificar que mp4Blob sea un Blob válido
    if (!(mp4Blob instanceof Blob)) {
      throw new Error("El archivo convertido no es un Blob válido.");
    }

    // Verificar que el archivo convertido no esté vacío
    if (mp4Blob.size === 0) {
      throw new Error("El archivo convertido está vacío.");
    }

    return mp4Blob;
  };

  function filterList(text) {

    var expr = /[áàéèíìóòúùñ]/gi;

    let filter = [];
    CargarChatsFilter.forEach((chat) => {
      if (
        chat.Usuario.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1
      ) {

        filter.push(chat);
      }
    });
  }

  function filterOptions(input, option) {
    var expr = /[áàéèíìóòúùñ]/ig;
    return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
  }

  function filterSort(optionA, optionB) {
    var expr = /[áàéèíìóòúùñ]/ig;
    return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
  }

  // MODAL ELIMINAR CITA
  const [modal, ModalEliminarChat] = Modal.useModal();
  const confirm = () => {
    modal.confirm({
      title: "¿Desea eliminar este chat?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: () => deleteChat(),
      onCancel: () => setEstatusModalEliminarChat(false),
      open: EstatusModalEliminarChat,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>El chat con {DatosContacto.nombre.toUpperCase()} sera eliminado de forma permanente</p>
        </div>
      ),
    });
  };

  async function readModule() {
    await getAuthFirebase_request({ accion: 1, modulo: "chat", userId: userInfo.id });
  }

  ///////////////////////////////////////////

  useEffect(() => {
    // TODO revisar!
    const initChat = async () => {

      setLoader(false);
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
    readModule();
    dispatch(badges({ accion: 0, modulo: "M2", }));

    initChat();

    return () => {
      pusher.disconnect();
    }
  }, []);

  useEffect(() => {
    if (SelectedChat === '') {
      setReferencia('')
    }
  }, [SelectedChat])

  // Modal style
  const styleDet = {
    height: "clac(100vh - 50px)",
    width: "clac(100vw - 50px)",
  };

  const renderMessages = () => {
    let messagesObj = [];

    if (messages.length > 0) {
      messages.map((chats, index) => {
        if (index === messages.length - 1) console.log(chats)
        messages.push(
          <MessageCard message={chats} DatosContacto={DatosContacto} key={`${chats.id}`} />
        )
      });
    }
    else {
      return <></>
    }

    return messagesObj;
  }

  return (
    <Stack>
      <NavbarNuevo
        modulo={"Chat"}
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        searchBar={
          <div className="headerChat">
            <div className="Encabezado-Chat" style={{ alignItems: "center" }}>
              <b>Chat</b>
              <ButtonAntd
                tipo={1}
                style={{ width: "100px" }}
                onClick={OpenSelectContacto}
                children={
                  <Row style={{ gap: ".5rem", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                    Nuevo
                    <img style={{ width: "12px" }} src={Agregar} alt="" />
                  </Row>
                }
              />
            </div>
            <Input
              prefix={<Search style={{ color: "#d7d7d7", width: "18px" }} />}
              style={{ width: "45%" }}
              placeholder="Paciente"
              type="text"
              onChange={filterList}
            />

          </div>
        }
      />

      {/* ########### VISTA CON MODAL PARA CONVERSACION ###########*/}
      <div className="mode-modal-chat">
        <Content
          className="site-layout"
          style={{
            padding: '0 3%',
            position: "relative",
            width: "100%",
            overflow: 'initial',
          }}
        >
          {isLoadingChats && chats.length === 0 ? (
            <Loader Loading={loader} />
          ) : (
            <>
              {chats.length === 0 ? (
                <Empty description={<span style={{ color: "black" }}>Sin chats</span>} />
              ) :
                <>
                  {isLoadingChats &&
                    <div style={{ width: '100%', textAlign: 'center', marginBottom: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <LoaderCircle style={{ display: "inline-block" }} Width={20} Height={20} /><p style={{ fontSize: 14, marginLeft: 10, fontStyle: 'italic' }}>Actualizando Conversaciones....</p>
                    </div>
                  }
                  <ChatCard
                    OpenChatModal={OpenChatModal}
                    ChatsCarga={chats}
                    chatSolicitud={chatSolicitud}
                    userData={userInfo}
                    screenMode={1}
                    pusher={pusher}
                    pusherDatosTemp={pusherDatosTemp}
                  />
                </>
              }
            </>
          )}
        </Content>
      </div>
      {/* #########################################################*/}

      {/* ########### VISTA DIVIDIDA PARA CONVERSACION ###########*/}
      <div className="gridMessagesView">
        <Col className="messagesViewContent" span={12}>
          {isLoadingChats && chats.length === 0 ? (
            <Loader Loading={loader} />
          ) : (
            <div className="ScrollListChats">
              {chats.length === 0 ? (
                <Empty description={<span style={{ color: "black" }}>Sin chats</span>} />
              ) :
                <>
                  {isLoadingChats &&
                    <div style={{ width: '100%', textAlign: 'center', marginBottom: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <LoaderCircle style={{ display: "inline-block" }} Width={20} Height={20} /><p style={{ fontSize: 14, marginLeft: 10, fontStyle: 'italic' }}>Actualizando Conversaciones....</p>
                    </div>
                  }
                  <ChatCard
                    OpenChatModal={OpenChatModal}
                    ChatsCarga={chats}
                    chatSolicitud={chatSolicitud}
                    userData={userInfo}
                    setShowViewMessages={setShowViewMessages}
                    screenMode={2}
                    SelectedChat={SelectedChat}
                    setSelectedChat={setSelectedChat}
                    pusher={pusher}
                    pusherDatosTemp={pusherDatosTemp}
                  />
                </>
              }
            </div>
          )}
        </Col>
        <Col className="messagesViewContent" span={12}>
          <div className={!ShowViewMessages ? "ScrollMessageView hidden" : "ScrollMessageView show"}>
            <MessagesView
              messages={messages}
              DatosContacto={DatosContacto}
              chatContainerDivididoRef={chatContainerDivididoRef}
              loaderSendMessage={loaderSendMessage}
              FilePreview={FilePreview}
              setFilePreview={setFilePreview}
              setMessageToSend={setMessageToSend}
              setMessageToSendTemp={setMessageToSendTemp}
              sendMessage={sendMessage}
              handleFileSelect={handleFileSelect}
              setChatOption={setChatOption}
              confirm={confirm}
              messageToSend={messageToSend}
              setOpen={setOpen}
              setTypeMessage={setTypeMessage}
              setMessage={setMessage}
              setShowViewMessages={setShowViewMessages}
              loaderChat={loaderChat}
              setSelectedChat={setSelectedChat}
              OpenChatModal={OpenChatModal}
              setFromNewView={setFromNewView}
            />
          </div>
          {!ShowViewMessages && (
            <div className="emptyMessageView">
              <div>
                <QuestionAnswer htmlColor="rgb(96, 99, 102)" fontSize="large" />
                <p>Envia y recibe mensajes de tus pacientes por medio del chat</p>
              </div>
            </div>
          )}

        </Col>
      </div >
      {/* #########################################################*/}

      < Modalchat isOpen={isOpenChatModal} closeModal={CloseChatModal} optionClose={false} styleDet={styleDet} >
        <div className="ModalChat">
          {/*  // ******** inicio Front de Mensajes ******** */}
          <div
            className="usuario-seleccionado"
          >
            <div style={{ display: "flex", alignItems: "center", gap: "2%", width: "95%" }}>
              {(ChatOption === 2 && !FromNewView) && (
                <ArrowBack onClick={() => {
                  setChatOption(1);
                }} />
              )}

              {FromNewView && (
                <CloseIcon onClick={() => {
                  CloseChatModal(true);
                  cleanInputsAdd();
                  setChatOption(1);
                }} />
              )}



              <Avatar size={55} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                className="GeneralNoImage"
                src={
                  <Image
                    style={{ background: "#d7d7d7" }}
                    src={
                      !!DatosContacto.imagen
                        ? "https://" + DatosContacto.imagen
                        : Imag
                    }
                  />
                } />
              <div className="">
                <span style={{ color: "white", fontWeight: "500", wordBreak: "break-all" }}>{DatosContacto.nombre}</span>
              </div>

              {ChatOption === 1 && (
                <Dropdown
                  menu={{ items, }}
                  trigger={["click"]}
                >
                  <ListItemDecorator>
                    <MoreVert style={{ cursor: "pointer" }} />
                  </ListItemDecorator>

                </Dropdown>
              )}
            </div>
            {ChatOption === 1 && (
              <CloseIcon
                onClick={CloseChatModal} className="opciones" style={{ cursor: "pointer", fontSize: "20px" }}
              />


            )}
          </div>
          {ChatOption === 1 && (
            <div key='option1'>
              <div id="Chatmessage-modal" className="Chatmessage-modal" ref={chatContainerRef}>
                {loaderChat ? (
                  <LoaderModals className="addRecetaChat" />
                ) : (

                  messages.map((chats) => (
                    <MessageCard message={chats} DatosContacto={DatosContacto} />
                  ))

                )}

              </div>
              <BottomBarChat
                FilePreview={FilePreview}
                loaderSendMessage={loaderSendMessage}
                setFilePreview={setFilePreview}
                handleFileSelect={handleFileSelect}
                messageToSend={messageToSend}
                sendMessage={sendMessage}
                setMessageToSend={setMessageToSend}
                setMessageToSendTemp={setMessageToSendTemp}
                setOpen={setOpen}
                setTypeMessage={setTypeMessage}
                setMessage={setMessage}
              />
            </div>
          )}

          {ChatOption === 2 && (
            <>
              {loaderDetalle ? (
                <LoaderModals className="addRecetaChat" />
              ) : (
                <div className="addRecetaChat" style={{ textAlign: "start" }}>
                  <Content
                    className="site-layout"
                    style={{
                      padding: '1% 10px',
                      // height: "calc(70vh - 52px)",
                      width: "100%",
                      overflow: 'initial',
                      // background: "#eaeef0"
                    }}
                  >

                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", }}>
                      <Checkbox
                        defaultChecked={true}
                        checked={CheckEnviar}
                        onChange={(e) => {
                          setCheckEnviar(e.target.checked)
                        }}
                      >Enviar a paciente</Checkbox>
                      <Checkbox
                        defaultChecked={true}
                        checked={CheckDiagnostico}
                        onChange={(e) => {
                          setCheckDiagnostico(e.target.checked);
                        }}
                      >Diágnostico</Checkbox>
                      <Checkbox
                        defaultChecked={true}
                        checked={CheckIndicaciones}
                        onChange={(e) => {
                          setCheckIndicaciones(e.target.checked);
                        }}
                      >Indicaciones</Checkbox>
                      <Checkbox
                        defaultChecked={true}
                        checked={CheckFirma}
                        onChange={(e) => {
                          setCheckFirma(e.target.checked);
                        }}
                      >Firma</Checkbox>
                      <Button
                        style={{
                          display: "flex", justifyContent: "space-between", alignItems: "center",
                          width: "100px",
                          backgroundColor: "#148f9f",
                          color: "white",

                          gap: "1%"
                        }}
                        onClick={() => {
                          addReceta();
                        }}
                      >Guardar <CheckCircleIcon style={{ fontSize: "15px" }} /></Button>
                    </div>
                    <form action="" style={{ padding: "0" }}>
                      <Row
                        style={{
                          marginBottom: "2%",
                          // justifyContent: "end",
                        }}
                      >

                        <Campos
                          label={"Costo:"}
                          styleDiv={{ width: "30%" }}
                        >
                          <InputNumber
                            // style={{ marginBottom: "2%", width:"40%" }}
                            style={{ width: "100%", height: "60%", display: "flex" }}
                            defaultValue={0}
                            formatter={(value) =>
                              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            onChange={(e) => setCostoReceta(e)}
                          />
                        </Campos>
                      </Row>

                      <Campos
                        valueError={MensajeCedula}
                        textError={"Debe seleccionar una cédula"}
                        label={"Cédula profesional"}
                      >
                        <Select
                          placeholder="Cédula profesional"
                          defaultValue={null}
                          style={{
                            width: "100%",
                          }}
                          onSelect={(e) => {
                            setCedula(e);
                            setMensajeCedula("true");
                          }}
                          options={CedulasMedico}
                        />
                      </Campos>

                      <Campos label="Medicamento">
                        <div style={{ width: "100%", position: "relative" }}>
                          <Autosuggest
                            suggestions={Medicamentos}
                            onSuggestionsFetchRequested={
                              onSuggestionsFetchRequested2
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested2
                            }
                            getSuggestionValue={getSuggestionValueDos}
                            renderSuggestion={renderSuggestionDos}
                            inputProps={inputPropsDos}
                            onSuggestionSelected={eventEnterDos}
                          />
                          <LeyendaError valido={MensajeMedicamento}>Debe escribir un medicamento</LeyendaError>
                        </div>
                      </Campos>

                      <InputAntd
                        value={Dosis}
                        placeholder={"Dósis"}
                        label={"Dósis"}
                        styleDiv={{ width: "100%" }}
                        valueError={MensajeDosis}
                        setStateError={setMensajeDosis}
                        vacio={false}
                        textError={["Debe escribir una dósis"]}
                        setStateValue={setDosis}
                      />

                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          style={{
                            display: "flex", justifyContent: "space-between", alignItems: "center",
                            width: "100px",
                            backgroundColor: "#148f9f",
                            color: "white",
                            marginTop: "15px"
                          }}
                          onClick={() => {
                            if (!!Cedula) {
                              addMedicamento();
                            } else {
                              setOpen(true);
                              setTypeMessage("warning");
                              setMessage("Debe seleccionar una cédula");
                            }
                          }}
                        >Agregar <AddCircleIcon style={{ fontSize: "15px" }} /> </Button>

                      </div>

                      <hr style={{ margin: "1% 0", color: "#d7d7d7" }} />

                      <h3 style={{ marginBottom: "5%", textAlign: "center" }}>
                        Medicamentos
                      </h3>
                      <LeyendaError
                        valido={MensajeListaMedicamentos}
                        style={{
                          display:
                            MensajeListaMedicamentos === "false" ? "flex" : "none",
                        }}
                      >
                        Debe agregar al menos un medicamento
                      </LeyendaError>
                      <div>
                        {ListaMedicamentos.map((medicamento, index) => (

                          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1%", borderBottom: "1px solid #d7d7d7" }}>
                            <span style={{ width: "80%" }}>{`${medicamento.Concepto} - ${medicamento.Descripcion}`}</span>
                            <img style={{ width: "20px" }} onClick={() => { eliminarMedicamento(medicamento.index) }} src={Eliminar} alt="" />
                          </div>

                        ))}
                        {ListaMedicamentos.length === 0 && (
                          <p style={{ marginBottom: "5%", textAlign: "center" }}>
                            Aún no se agregan medicamentos
                          </p>
                        )}
                      </div>

                      <TextAreaAntd
                        autoSize={true}
                        label={"Indicaciones"}
                        placeholder={"Escribe algunas indicaciones"}
                        value={Indicaciones}
                        setStateValue={setIndicaciones}
                        cols="30"
                      />

                      <Label valido={MensajeDiagnosticoCIIE}>Diagnósticos CIE</Label>
                      <div style={{ display: "flex", gap: "1", margin: "1% 0" }}>
                        <InputAntd
                          className="InputNormal"
                          type=""
                          placeholder="Palabra clave"
                          value={PalabraClave}
                          setStateValue={setPalabraClave}
                          vacio={true}
                          disabled={false}
                          style={{ width: "100%" }}
                          setStateError={setMensajePalabraClave}
                          textError={["Debe escribir una palabra clave", ""]}
                          valueError={MensajePalabraClave}
                        />
                        {loaderSearch ? (<CircularProgress size={35} sx={{ color: "#148f9f" }} />) : (
                          <Button
                            type="button"
                            style={{ margin: "0 1%", marginLeft: "1%", border: "0", background: "#148f9f", color: "white" }}
                            onClick={() => {
                              if (!!PalabraClave) {
                                getCIIEDiagnosticos();
                              } else {
                                setMensajePalabraClave("false");
                                setOpen(true);
                                setTypeMessage("warning");
                                setMessage("Debe escribir una palabra");
                              }
                            }}>Buscar</Button>
                        )}
                      </div>

                      <div>
                        {ListaDiagnosticos.length > 0 && (
                          <>
                            <div style={{ display: "flex", gap: "1rem" }}>
                              <Select
                                value={DiagnosticosSeleccionados}
                                mode="multiple"
                                placeholder="Seleciona tus diagnósticos"
                                style={{ width: "100%" }}
                                options={ListaDiagnosticos}
                                filterOption={filterOptions}
                                filterSort={filterSort}
                                onChange={(e) => {
                                  setDiagnosticosSeleccionados(e);
                                }}
                              />
                              <Button
                                icon={<Add style={{ color: "white" }} />}
                                style={{ background: "#148f9f" }}
                                onClick={() => {
                                  addDiagnosticoCIIE();
                                }}
                              />
                            </div>
                            <LeyendaError valido={MensajeDiagnosticoCIIE}>Debe seleccionar un diagnóstico</LeyendaError>
                          </>
                        )}
                        <Label valido={MensajeOtrosDiagnosticos}>Otros diagnósticos</Label>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <TextAreaAntd
                            value={OtrosDiagnosticos}
                            placeholder="Otros diagnósticos"
                            style={{
                              marginBottom: ".5%",
                              fontFamily: "sans-serif",
                            }}
                            columns="100"
                            autoSize={true}
                            setStateValue={setOtrosDiagnosticos}
                            setStateError={setMensajeOtrosDiagnosticos}
                            valueError={MensajeOtrosDiagnosticos}
                            textError={["Debe escribir un diagnósticos"]}
                            vacio={true}
                          />
                          <Button
                            icon={<Add style={{ color: "white" }} />}
                            style={{ background: "#148f9f" }}
                            onClick={() => {
                              addDiagnosticoOtro();
                            }}
                          />
                        </div>
                      </div>
                      <h3 style={{ marginBottom: "5%", textAlign: "center" }}>
                        Diagnósticos
                      </h3>
                      {DiagnosticosSeleccionadosMostrados.length > 0 ? (
                        <>
                          {DiagnosticosSeleccionadosMostrados.map((diagnostico, index) => (
                            <div className="ItemCard">
                              <Row style={{ justifyContent: "space-between" }}>
                                <span>{diagnostico.diagnostico}</span>
                                <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                              </Row>
                            </div>

                          ))}
                        </>
                      ) : (
                        <p style={{ textAlign: "center" }}>Aún no se agregan diagnósticos</p>
                      )}
                    </form>
                  </Content>
                </div>
              )}
            </>
          )}

        </div>
      </ Modalchat>

      {ModalEliminarChat}

      < ModalSelectContact
        isOpen={IsOpenSelectContacto}
        closeModal={ClosedSelectContacto}
        optionClose={true}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          Selecciona un Contacto
          <CloseIcon
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              ClosedSelectContacto(true);
              // OpenTipoUsuario(true);
            }}
          />

        </div>
        <div className="ModalListContactosConsult">
          <p>Nombre</p>
          {contactos.isLoading ? <Loader /> :
            <>
              <Input
                placeholder="Buscar..."
                type="search"
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={filterContacts}
              />

              <div className="modal-chat-view">
                <AddLista
                  CloseModal={ClosedSelectContacto}
                  OpenModal={OpenChatModal}
                  Lista={contactListFilter}
                  funcionExtra={[5, setDatosContacto, newChat]}
                  style={{ borderTop: "1px solid #d5d5d5" }}
                />
              </div>
              <div className="grid-chat-view">
                <AddLista
                  CloseModal={ClosedSelectContacto}
                  Lista={contactListFilter}
                  funcionExtra={[5, setDatosContacto, newChat]}
                  style={{ borderTop: "1px solid #d5d5d5" }}
                  setShowViewMessages={setShowViewMessages}
                />
              </div>
            </>
          }
        </div>
      </ ModalSelectContact >

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack >
  );
}
