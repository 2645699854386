import dayjs from "dayjs";
import "dayjs/locale/es";
import { parse, format } from 'date-fns';

export const chars = {
  á: "a",
  é: "e",
  í: "i",
  ó: "o",
  ú: "u",
  à: "a",
  è: "e",
  ì: "i",
  ò: "o",
  ù: "u",
  ñ: "n",
  Á: "A",
  É: "E",
  Í: "I",
  Ó: "O",
  Ú: "U",
  À: "A",
  È: "E",
  Ì: "I",
  Ò: "O",
  Ù: "U",
  Ñ: "N",
};

export const normalizeText = (text) => {
  return text.replace(/[áàéèíìóòúùñÁÉÍÓÚÀÈÌÒÙÑ]/g, (match) => chars[match] || match);
}

export const getCalendarRange = () => {
  const startDate = dayjs().subtract(1, 'month').startOf('month').toISOString();
  const endDate = dayjs().add(1, 'month').endOf('month').toISOString();

  return [startDate, endDate];
}

export const getComboHorariosData = (horarios) => {
  let array = [];
  horarios.forEach((element) => {
    array.push({ value: element, label: element });
  });

  return array;
}

export const getHorariosDisponiblesByCitas = (citas, Horarios) => {
  console.log(citas)
  console.log(Horarios)
  let horarios_disponibles = [...Horarios];

  citas.forEach((cita) => {
    console.log(cita)
    let hora = `${cita.HoraCita.substring(0, 1) === "0"
      ? cita.HoraCita.substring(1, 5)
      : cita.HoraCita.substring(0, 5)
      } ${cita.HoraCita.substring(
        cita.HoraCita.length - 5,
        cita.HoraCita.length
      ) === "a. m."
        ? "a.m"
        : "p.m"
      }`;
    console.log(hora)
    if (horarios_disponibles.includes(hora)) {
      console.log('remove: ' + hora)
      const index = horarios_disponibles.indexOf(hora);
      console.log(index)
      horarios_disponibles.splice(index, 1);
      console.log(horarios_disponibles);
    }
  });

  return horarios_disponibles;
}

export const getEdad = (dateString) => {
  let hoy = new Date();
  let fNacimiento = new Date(dateString.replace(/-/g, "/"));
  let edad = hoy.getFullYear() - fNacimiento.getFullYear();
  let diferenciaMeses = hoy.getMonth() - fNacimiento.getMonth();
  if (
    diferenciaMeses < 0 ||
    (diferenciaMeses === 0 && hoy.getDate() < fNacimiento.getDate())
  ) {
    edad--;
  }

  return edad;
}

export const getDateFromString = (fechaString, horaString) => {
  const resultDate = dayjs(`${fechaString} ${horaString}`, 'DD-MM-YYYY HH:mm:ss');

  let tiempoCita = localStorage.getItem("duracionCita");

  return [
    resultDate.toLocaleString(),
    resultDate.add(parseInt(tiempoCita), 'minutes').toLocaleString()
  ];
}

export const stringDateFormat = (strFecha, formatStr) => {
  // Parsea la cadena de fecha a un objeto Date
  const dt = parse(strFecha, 'yyyy-MM-dd HH:mm:ss', new Date());

  // Formatea la fecha según el formato especificado
  return format(dt, formatStr);
}

export const formatFechaNewMessage = (fechaString) => {

  // Crear un objeto Date a partir de la cadena de fecha
  const fecha = new Date(fechaString.replace(" ", "T")); // Convertir a formato ISO

  // Extraer los componentes de la fecha
  const dia = String(fecha.getDate()).padStart(2, '0'); // Día con dos dígitos
  const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Mes con dos dígitos
  const año = fecha.getFullYear();

  // Extraer la hora y los minutos
  let hora = fecha.getHours();
  const minutos = String(fecha.getMinutes()).padStart(2, '0'); // Minutos con dos dígitos

  // Determinar si es AM o PM
  const ampm = hora >= 12 ? 'PM' : 'AM';

  // Convertir la hora a formato 12 horas
  hora = hora % 12;
  hora = hora ? hora : 12; // La hora 0 debe ser 12

  // Formatear la fecha y la hora
  const fechaFormateada = `${dia}-${mes}-${año} ${String(hora).padStart(2, '0')}:${minutos} ${ampm}`;

  console.log(fechaFormateada); // Salida: "17-02-2025 02:32 PM"
  return fechaFormateada;
}

export const delayCode = (ms) => new Promise(resolve => setTimeout(resolve, ms));