import { useQueryClient, useQuery, QueryFunction } from "@tanstack/react-query";

import { getContacts_request } from "../../services/contactos_services";

const QUERY_KEY = ['Contactos'];
const fetchInterval = 5; // Minutos

const fetchData = async () => {
    let response = await getContacts_request();

    return response.data ?? [];
}

// TODO usar token para activar query
const useContactosData = (token) => {
    const queryEnabled = token ? true : false;
    return useQuery({
        queryFn: fetchData,
        queryKey: QUERY_KEY,
        refetchInterval: (fetchInterval * 60000),
    })
};

export default useContactosData;