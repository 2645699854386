import axios from "axios"
import baseUrl from "./apiUrl";

export async function upfile_request(idcontact, multimedia) {
    // console.log(idcontact, multimedia);
    try {
        let resp;
        // console.log(socket.id);
        const response = await axios({
            url: baseUrl + "/MensajeNuevo/" + idcontact,
            method: "POST",
            data: multimedia,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        //  // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido"
        };
    }
}

export async function chats_request() {
    try {
        let resp;
        // console.log(socket.id);
        const response = await axios({
            url: baseUrl + "/Mensajes",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido"
        };

    }
}

export async function solicitudChat_request(Referencia, timestamp) {
    try {
        let resp;
        
        const response = await axios({
            url: `${baseUrl}/Mensajes/${Referencia}?timestamp=${timestamp}`,
            method: "GET",
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        });

        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        console.log(error)
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido"
        };

    }
}

export async function getReferenceOfChat(idContacto) {
    try {
        let resp;
        const response = await axios({
            url: `${baseUrl}/Referencia/${idContacto}`,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })

        if (response.status === 200) {
            if (response.data.Success) {
                resp = {
                    "ok": true,
                    "status": 200,
                    "data": {
                        "Referencia": response.data.Respuesta.Referencia,
                        "Canal": response.data.Respuesta.Canal
                    }
                };
            } else if (!response.data.Success) {
                resp = {
                    "ok": true,
                    "status": 200,
                    "data": {
                        "Referencia": false,
                        "Canal": false
                    }
                };
            } else {
                resp = {
                    "ok": true,
                    "status": 200,
                    "data": {
                        "Referencia": null,
                        "Canal": null
                    }
                };
            }

        } else {
            resp = {
                "ok": false,
                "status": 404,
                "data": {
                    "Referencia": null,
                    "Canal": null
                }
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido"
        };

    }
}

export async function sendMessage_request(idContacto, dataMensaje) {
    try {
        let resp;
        // console.log(idSolicitud);
        const response = await axios({
            url: baseUrl + "/MensajeNuevo/" + idContacto,
            method: "POST",
            data: dataMensaje,
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido"
        };

    }
}

export async function deleteChat_request(Referencia) {
    try {
        let resp;
        // console.log(idSolicitud);
        const response = await axios({
            url: baseUrl + "/eliminarChat/" + Referencia,
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log(response);
        if (response.status === 200 || response.status === 20) {
            resp = {
                "ok": true,
                "status": 200,
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido"
        };

    }
}
