import React, { useState } from "react";
import "./styles/ChatsCard.scss";
import { Avatar, Badge } from "antd";
import { DoneAll, FilePresent, Image, MicNone } from "@mui/icons-material";
import { stringDateFormat } from "../../utils/utils";


function ChatCard({ ChatsCarga, chatSolicitud, OpenChatModal, userData, setShowViewMessages, screenMode,
  SelectedChat, setSelectedChat, pusher, pusherDatosTemp }) {

  function formatDate(fecha) {
    const now = new Date();

    // Obtener los componentes de la fecha y hora
    const año = now.getFullYear();
    const mes = String(now.getMonth() + 1).padStart(2, '0'); // Añadir un 0 al principio si es necesario
    const dia = String(now.getDate()).padStart(2, '0');
    const horas = String(now.getHours()).padStart(2, '0');
    const minutos = String(now.getMinutes()).padStart(2, '0');
    const segundos = String(now.getSeconds()).padStart(2, '0');

    // Formatear la fecha y hora en el formato deseado
    const formatoDeseado = `${año}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;

    // console.log(stringDateFormat(fecha, 'dd/MM/yy'));
    // console.log(stringDateFormat(formatoDeseado, 'dd/MM/yy'));

    if (stringDateFormat(fecha, 'dd') == stringDateFormat(formatoDeseado, 'dd')) {
      // console.log(stringDateFormat(fecha, 'h:mm a'));
      return stringDateFormat(fecha, 'h:mm a');
    }
    return stringDateFormat(fecha, 'dd/MM/yy');

  }

  return (
    <>
      {ChatsCarga.map((lista, index) => (
        <div key={index} id="solicitudCard" onClick={() => {
          // Verificar si ya estás suscrito a un canal específico
          const existingChannel = pusher.channel(pusherDatosTemp.canal);
          if (existingChannel) {
            // Desuscribirse del canal existente
            pusher.unsubscribe(pusherDatosTemp.canal);
            console.log('Desuscrito del canal: my-old-channel');
          }
          chatSolicitud(lista);
          if (screenMode === 1) {
            OpenChatModal(true);
          } else {
            setSelectedChat(index);
            setShowViewMessages(true);
          }
        }}>
          <div className={SelectedChat == index ? "CardChat seleccionado" : "CardChat"} style={{ cursor: "pointer" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "1%", width: "100%" }}>
                <Avatar
                  size={45}
                  className="BgrImg"
                  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  src={"https://" + lista.Imagen_Url}
                />

                <div style={{ width: "90%" }}>
                  <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <b className="overflow-ellipsis-chat">{lista.Usuario}</b>
                    <p style={{ fontSize: "12px" }}>{formatDate(lista.fecha)}</p>
                  </div>
                  <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ width: "80%", display: 'flex', alignItems: "center" }}>
                      {userData.id === lista.IdUsuario && (<DoneAll fontSize="12px" />)}
                      {lista.TipoMensaje.toString() === "0" && (
                        <p style={{ display: "flex", overflow: "hidden", textOverflow: "ellipsis", height: "24px" }}>{lista.message}</p>
                      )}
                      {lista.TipoMensaje.toString() === "1" && (
                        <>
                          <FilePresent style={{ color: '#148f9f' }} />
                          <p style={{ width: "80%", display: "flex", overflow: "hidden", textOverflow: "ellipsis", height: "24px" }}>Archivo adjunto</p>
                        </>
                      )}
                      {lista.TipoMensaje.toString() === "2" && (
                        <>
                          <Image style={{ color: '#148f9f' }} />
                          <p style={{ width: "80%", display: "flex", overflow: "hidden", textOverflow: "ellipsis", height: "24px" }}>Imagen</p>
                        </>
                      )}
                      {lista.TipoMensaje.toString() === "3" && (
                        <>
                          <MicNone style={{ color: '#148f9f' }} />
                          <p style={{ width: "80%", display: "flex", overflow: "hidden", textOverflow: "ellipsis", height: "24px" }}>Mensaje de voz</p>
                        </>
                      )}

                    </div>

                    <Badge count={lista.MensajesSinVer} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );

}

export default ChatCard;