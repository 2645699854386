import { Pause, PlayArrow } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import "./styles/waveform.scss"

const Waveform = ({ audioUrl, isMe }) => {
    const waveformRef = useRef(null); // Referencia al contenedor de la forma de onda
    const wavesurferRef = useRef(null); // Referencia a la instancia de WaveSurfer
    const [isPlaying, setIsPlaying] = useState(false); // Estado para play/pause
    const [duration, setDuration] = useState(0); // Duración del audio
    const [currentTime, setCurrentTime] = useState(0); // Tiempo actual de reproducción

    // Inicializar WaveSurfer
    useEffect(() => {
        if (!waveformRef.current) return;

        // Crear una instancia de WaveSurfer
        wavesurferRef.current = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: isMe ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0,0,0,0.3)',
            progressColor: isMe ? 'rgb(255,255,255)' : '#148f9f',
            cursorColor: isMe ? 'white' : '#148f9f',
            barWidth: 2.5,
            barHeight: 2,
            responsive: true,
            height: 40,
            barGap: 2,
            fetchParams:{
                mode: 'no-cors'
            }
        });

        // Cargar el archivo de audio
        wavesurferRef.current.load(audioUrl);

        // Escuchar eventos de WaveSurfer
        wavesurferRef.current.on('ready', () => {
            setDuration(wavesurferRef.current.getDuration());
        });

        wavesurferRef.current.on('audioprocess', () => {
            setCurrentTime(wavesurferRef.current.getCurrentTime());
        });

        wavesurferRef.current.on('finish', () => {
            setIsPlaying(false);
            wavesurferRef.current.seekTo(0); // Regresar al inicio de la onda
            setCurrentTime(0);
        });

        // Limpiar al desmontar el componente
        return () => {
            wavesurferRef.current.destroy();
        };
    }, [audioUrl]);

    // Función para play/pause
    const togglePlayPause = () => {
        wavesurferRef.current.playPause();
        setIsPlaying(!isPlaying);
    };

    // Formatear el tiempo en minutos y segundos
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div style={{display:'flex', flexDirection: 'row', width:'250px'}}>
            {/* Contenedor de la forma de onda */}
            <div style={{flexGrow:1}}>
                <div ref={waveformRef} style={{ marginBottom: '10px', paddingTop: "10px", paddingBottom: "10px", paddingInline: '5px' }}></div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyItems:'center'}}>
                {isPlaying ? 
                    (<Pause style={{ color: !isMe ? '#148f9f' : 'white'}} onClick={togglePlayPause} />) : 
                    (<PlayArrow style={{ color: !isMe ? '#148f9f' : 'white'}} onClick={togglePlayPause} />)
                }
                <span style={{ color: !isMe ? '#148f9f' : 'white', fontSize:10}}>
                    {formatTime(currentTime)} / {formatTime(duration)}
                </span>
            </div>
        </div>
    );
};

export default Waveform;