import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Icono from "../../IconosDeSitio";
import { AddCircle, Edit, OndemandVideo } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

import ModalNotaMedica from "../../GeneralComponents/ModalDetalle";
import ModalAddNota from "../../GeneralComponents/ModalDetalle";
import ModalResumenMedico from "../../GeneralComponents/ModalDetalle";


import { UseModal } from "../../../hooks/UseModal";
import "../styles/ExpedienteNotasMedicas.scss";
import SelectAntd from "../../GeneralComponents/SelectAntd"
import TextAreaAntd from "../../GeneralComponents/TextAreaAntd"
import { Button, Empty, Image, Layout, Row } from "antd";
// Librerias mui acordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputAntd from "../../GeneralComponents/InputAntd";
import { CircularProgress } from "@mui/material";
import { Input, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import LoaderModals from "../../GeneralComponents/Loader_modals";
import NotasMedicas from "./NotasMedicasCard";
// Importando tablas
import {
  detailConsultas_request,
  addResumenMedico_request,
  previewResumenMedico_request,
  documentoResumenMedico_request
} from "../../../services/consultas_services";

function ExpedienteNotasMedicas({ DatosContacto, NotasEvolucion, AccionesModal, AccionesMensaje }) {
  let data = JSON.parse(sessionStorage.getItem("infoUser"));
  // useState del modal
  const [isOpenModalNotasMed, OpenModalNotasMed, CloseModalNotasMed] = UseModal(false);
  // useState del AddNota
  // const [isOpenModalAddNota, OpenModalAddNota, CloseModalAddNota] = UseModal(false);

  const [IsOpenResumenMedico, OpenResumenMedico, ClosedResumenMedico] = UseModal(false);

  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const columns = [
    {
      title: 'Medicamento',
      dataIndex: 'Concepto',
      key: 'medicamento',
    },
    {
      title: 'Dosis',
      dataIndex: 'Descripcion',
      key: 'dosis',
    }
  ];

  const navigate = useNavigate();

  // MOTIVO CONSULTA
  const [MotivoConsulta, setMotivoConsulta] = useState("");

  // Modal Tipo de dconsulta
  const [TipoConsulta, setTipoConsulta] = useState(null);

  // Estatus mensajes DATOS CONSULTA
  const [MensajeMotivoConsulta, setMensajeMotivoConsulta] = useState("true");
  const [MensajeTipoConsulta, setMensajeTipoConsulta] = useState("true");

  const [MensajeResumenMedico, setMensajeResumenMedico] = useState("true");

  // const [NotasEvolucion, setNotasEvolucion] = useState([]);
  // const [NotasEvolucionFilter, setNotasEvolucionFilter] = useState([]);

  const [DetalleConsulta, setDetalleConsulta] = useState("");
  const [Sexo, setSexo] = useState(null);
  const [Edad, setEdad] = useState(0);
  // EVOLUCION DEL PADECIMIENTO
  const [EvolucionPadecimiento, setEvolucionPadecimiento] = useState("");
  const [IMC, setIMC] = useState("");
  const [DiagnosticosSeleccionadosMostrados, setDiagnosticosSeleccionadosMostrados] = useState([]);

  const [ListaRecetas, setListaRecetas] = useState([]);

  // RECOMENDACIONES
  const [Recomendaciones, setRecomendaciones] = useState("");
  const [RowsReceta, setRowsReceta] = useState([]);

  const [loaderResumenMedico, setLoaderResumenMedico] = useState(false);
  const [ResumenMedico, setResumenMedico] = useState("");

  const [IdConsulta, setIdConsulta] = useState("");

  const [loaderDetalle, setLoaderDetalle] = useState(false);

  async function detailConsulta(idConsulta) {
    setIdConsulta(idConsulta);
    setLoaderDetalle(true);
    // console.log(idConsulta);
    let response = await detailConsultas_request(idConsulta);
    if (response.ok) {
      console.log(response.data);
      setDetalleConsulta(response.data);
      // if (response.data.Partidas.length > 0) {
      //   let partidas = [];
      //   response.data.Partidas.forEach((element, index) => {
      //     partidas.push({
      //       cantidad: element.Cantidad,
      //       medicamento: element.Concepto,
      //       dosis: element.Descripcion,
      //     })
      //   });
      // console.log(partidas);
      //   setRowsReceta(partidas);
      // } else if (response.data.Partidas.length === undefined) {
      //   let partidas = [];
      //   partidas.push({
      //     cantidad: response.data.Partidas.Cantidad,
      //     medicamento: response.data.Partidas.Concepto,
      //     dosis: response.data.Partidas.Descripcion,
      //   })
      // console.log(partidas);
      //   setRowsReceta(partidas);
      // }
      if (!!response.data.Consulta.Peso && !!response.data.Consulta.Altura) {
        let peso_temp = response.data.Consulta.Peso;
        let altura_temp = response.data.Consulta.Altura;
        let imc = peso_temp / (altura_temp * altura_temp);
        setIMC(imc.toFixed(2));
      }
      if (!!response.data.Consulta.fechaNacimiento) {
        getEdad(response.data.Consulta.fechaNacimiento);
      }
      // setNacimiento(response.data.Consulta.fechaNacimiento);
      setListaRecetas(response.data.Receta);
      let diag_temp = !!response.data.Consulta.Diagnostico ? response.data.Consulta.Diagnostico.split(",") : [];
      // console.log(diag_temp);
      let array_temp = [];
      diag_temp.forEach(element => {
        array_temp.push({ index: "", diagnostico: element });
      });
      setDiagnosticosSeleccionadosMostrados(array_temp);
      setSexo(response.data.Consulta.sexo);
      setMotivoConsulta(response.data.Consulta.Motivo);
      setEvolucionPadecimiento(response.data.Consulta.EvolucionPadecimiento);
      setRecomendaciones(response.data.Consulta.Recomendaciones);
      // OpenModalNotasMed(true);
      // OpenModalNotasMed(true);
    } else {
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("error");
      AccionesMensaje.setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
  }

  async function addResumenMedico() {
    let data = new FormData();
    data.append("resumen", ResumenMedico)
    data.append("IdUsuario", DatosContacto.id)
    let response = await addResumenMedico_request(data);
    if (response.ok) {
      // console.log(response.data);
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("success");
      AccionesMensaje.setMessage("Resumen médico creado");
      cleanInputsDetalleConsulta();
      ClosedResumenMedico(true);
      window.open(response.data, "_blank");
      // resumenMedico(response.data);
    } else {
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("error");
      AccionesMensaje.setMessage(response.mensaje);
    }
  }

  async function resumenMedico(idResumen) {
    setLoaderDetalle(true);
    // console.log(idResumen);
    // return;
    let response = await documentoResumenMedico_request(idResumen);
    if (response.ok) {
      // console.log(response.data);
      window.open(response.data, "_blank");
    } else {
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("error");
      AccionesMensaje.setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function vistaPreviaResumenMedico() {
    setLoaderResumenMedico(true);
    let data = new FormData();
    data.append("resumen", ResumenMedico)
    data.append("IdUsuario", DatosContacto.id)
    if (!!ResumenMedico) {
      let response = await previewResumenMedico_request(data);
      if (response.ok) {
        // console.log(response.data.resumen);
        window.open(response.data.resumen, "_blank");
      } else {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("error");
        AccionesMensaje.setMessage(response.mensaje);
      }
    } else {
      setMensajeResumenMedico("false");
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("warning");
      AccionesMensaje.setMessage("Faltan campos por completar");
    }
    setLoaderResumenMedico(false);
  }

  function crearResumenMedico() {

    let medicamentos_temp = [];
    if (ListaRecetas.length > 0) {
      ListaRecetas.forEach((receta) => {
        receta.partidas.forEach((medicamento) => {
          medicamentos_temp.push(medicamento);
        });
      });
    }
    let diagnosticos_temp = []
    if (DiagnosticosSeleccionadosMostrados.length > 0) {
      DiagnosticosSeleccionadosMostrados.forEach(element => {
        diagnosticos_temp.push(element.diagnostico);
      });
    }
    let resumen =
      `Paciente: \n${DatosContacto.nombre}\n
      \nEdad:\n${!!Edad ? Edad : "No especificada"}\n
      \nGénero:\n${Sexo}\n
      \nMotivo de consulta:\n${MotivoConsulta}\n
      \nEvolución del padecimiento:\n${!!EvolucionPadecimiento ? EvolucionPadecimiento : "Sin evolución del padecimiento"}\n
      \nExploración física:\n${!!DetalleConsulta.Consulta.ExploracionFisica ? DetalleConsulta.Consulta.ExploracionFisica : "Sin exploración física"}\n
      \nDiagnósticos:\n${diagnosticos_temp.toString()}\n`;
    resumen += `\n\nTratamientos:`
    if (medicamentos_temp.length > 0) {
      medicamentos_temp.forEach(element => {
        resumen += `\n${element.Concepto} - ${element.Descripcion}`;
      });
      resumen += `\n\n`
    } else {
      resumen += `\nSin tratamiento\n\n`;
    }

    resumen += `\nIndicaciones: \n${!!Recomendaciones ? Recomendaciones : "Sin indicaciones"} `;

    setResumenMedico(resumen);

    OpenResumenMedico(true);

  }

  function cleanInputsDetalleConsulta() {
    setDetalleConsulta("");
    setListaRecetas([]);
    setDiagnosticosSeleccionadosMostrados([]);
    setSexo("");
    setMotivoConsulta("");
    setEvolucionPadecimiento("");
    setRecomendaciones("");
    setResumenMedico("");
  }

  function getEdad(dateString) {
    let hoy = new Date()
    let fechaNacimiento = new Date(dateString.replace(/-/g, "/"))
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--
    }
    // console.log(edad);
    setEdad(edad);
    // return edad;
  }

  function navigateEditConsulta() {
    navigate("/app/ModificarConsulta", {
      //  replace: false,
      state: {
        id: DatosContacto.id,
        imagen: DatosContacto.imagen,
        nombre: DatosContacto.nombre,
        idConsulta: IdConsulta,
      },
    });
  }

  // function filterList(text) {
  //   // console.log(text.target.value);
  //   // let palabra = text.target.value;
  //   // console.log(text.target.value);
  //   // console.log(solicitudesSearch);
  //   var chars = {
  //     "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
  //     "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
  //     "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
  //     "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
  //   }
  //   var expr = /[áàéèíìóòúùñ]/ig;
  //   let filter = [];
  //   // NotasEvolucionFilter.forEach((consulta) => {
  //   //   // console.log(expediente);
  //   //   if (consulta.motivo.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1 ||
  //   //     consulta.fecha.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1) {
  //   //     filter.push(consulta);
  //   //     // console.log(expediente);
  //   //   }
  //   // });

  //   // setNotasEvolucion(filter);
  // }

  return (
    <>
      <NotasMedicas notas={NotasEvolucion} getDetailNotaMedica={detailConsulta} setDesplegarInfoNotas={OpenModalNotasMed} />

      {NotasEvolucion.length === 0 && (
        <Empty
          description={
            <span style={{ color: "black" }}>Sin consultas</span>
          } />
      )}

      <ModalNotaMedica
        isOpen={isOpenModalNotasMed}
        closeModal={CloseModalNotasMed}
        styleDet={styleDet}
      >
        <div className="topCloseModalNotas">
          Detalle de Nota Medica
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={CloseModalNotasMed} />

        </div>
        <div className="ModalNotasMed" style={{ background: "#eaeef0" }}>
          {loaderDetalle ? (<LoaderModals className={"ModalNotasMed"} />) : (
            <>
              {(data.Tipo === 1 && (<div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  style={{
                    background: "#148f9f",
                    border: "0",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                  onClick={() => {
                    CloseModalNotasMed(true);
                    crearResumenMedico();
                  }}
                >
                  Resumen médico <AddCircle fontSize="15px" />
                </Button>
                <Button
                  type="button"
                  style={{
                    background: "#148f9f",
                    border: "0",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                  onClick={() => {
                    navigateEditConsulta();
                  }}
                >
                  Editar <Edit fontSize="15px" />
                </Button>
              </div>))}
              {/* <AcordionNotasMed /> */}
              <br />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <b>Fecha: {!!DetalleConsulta ? DetalleConsulta.Consulta.Fecha : ""}</b>
              </div>
              <TextAreaAntd
                label="Motivo de consulta:"
                value={!!DetalleConsulta ? DetalleConsulta.Consulta.Motivo : ""}
                placeholder="Motivo de consulta"
                style={{
                  marginBottom: ".5%",
                  fontFamily: "sans-serif",
                }}
                columns="30"
                autoSize={true}
                readOnly={true}
              />
              {/* Evolucion del padecimiento */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Evolucion del padecimiento</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextArea
                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.EvolucionPadecimiento : ""}
                    placeholder="Evolucion del padecimiento"
                    style={{
                      margin: ".5% 0",
                      fontFamily: "sans-serif",
                    }}
                    name=""
                    id=""
                    cols="30"
                    autoSize
                    readOnly={true}
                  />
                </AccordionDetails>
              </Accordion>
              {/* Exploración Física */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography> Exploración física</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <p style={{fontWeight:"500"}}>Signos vitales</p> */}


                  <div className="BotonesHistorial">
                    <InputAntd
                      label="Altura:"
                      className="InputNormal"
                      style={{ width: "100%" }}
                      placeholder="Altura (Mtrs.)"
                      value={!!DetalleConsulta ? DetalleConsulta.Consulta.Altura : ""}
                      vacio={true}
                      readOnly={true}
                    />


                    <InputAntd
                      label="Peso:"
                      className="InputNormal"
                      style={{ width: "100%" }}
                      type=""
                      placeholder="Peso (Kg.)"
                      value={!!DetalleConsulta ? DetalleConsulta.Consulta.Peso : ""}
                      vacio={true}
                      readOnly={true}
                    />
                    {!!IMC && (
                      <>

                        <InputAntd
                          label="IMC:"
                          className="InputNormal"
                          style={{ width: "100%" }}
                          type=""
                          placeholder="IMC"
                          value={IMC}
                          vacio={true}
                          readOnly={true}
                        />



                        <Input
                          className="InputNormal"
                          style={{ width: "100%" }}
                          type=""
                          placeholder="IMC"
                          value={!!IMC ? IMC < 18 ? "Insuficiente" : IMC >= 18 && IMC <= 25 ? "Peso normal" : IMC > 25 && IMC <= 30 ? "Sobrepeso" : IMC > 30 && IMC <= 40 ? "Obesidad" : IMC > 40 && "Obesidad mórbida" : ""}
                          readOnly={true}
                          status={!!IMC ? IMC < 18 ? "warning" : IMC >= 18 && IMC <= 25 ? "" : IMC > 25 && IMC <= 30 ? "warning" : IMC > 30 && IMC <= 40 ? "warning" : IMC > 40 && "error" : ""}
                        />

                      </>
                    )}
                  </div>

                  <div className="BotonesHistorial">

                    <InputAntd
                      label="T. Arterial"
                      className="InputNormal"
                      style={{ width: "100%" }}
                      type=""
                      placeholder="T. Arterial"
                      value={!!DetalleConsulta ? DetalleConsulta.Consulta.TensionArterial : ""}
                      vacio={true}
                      readOnly={true}
                    />


                    <InputAntd
                      label="F. Cardiaca"
                      className="InputNormal"
                      style={{ width: "100%" }}
                      type=""
                      placeholder="F. Cardiaca"
                      value={!!DetalleConsulta ? DetalleConsulta.Consulta.FrecuenciaCardiaca : ""}
                      vacio={true}
                      readOnly={true}
                    />

                    <InputAntd
                      label="F. Respiratoria"
                      className="InputNormal"
                      style={{ width: "100%" }}
                      type=""
                      placeholder="F. Respiratoria"
                      value={!!DetalleConsulta ? DetalleConsulta.Consulta.FrecuenciaRespiratoria : ""}
                      vacio={true}
                      readOnly={true}
                    />
                  </div>

                  <div className="BotonesHistorial">
                    <InputAntd
                      label="Temperatura"
                      className="InputNormal"
                      style={{ width: "100%" }}
                      type=""
                      placeholder="Temperatura"
                      value={!!DetalleConsulta ? DetalleConsulta.Consulta.Temperatura : ""}
                      vacio={true}
                      readOnly={true}
                    />

                    <InputAntd
                      label="O2"
                      className="InputNormal"
                      style={{ width: "100%" }}
                      type=""
                      placeholder="O2"
                      value={!!DetalleConsulta ? DetalleConsulta.Consulta.SaturacionOxigeno : ""}
                      vacio={true}
                      readOnly={true}
                    />
                  </div>
                  <TextArea
                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.ExploracionFisica : ""}
                    placeholder="Exploración Física"
                    style={{
                      margin: ".5% 0",
                      fontFamily: "sans-serif",
                    }}
                    name=""
                    id=""
                    cols="30"
                    autoSize
                    readOnly={true}
                  />
                </AccordionDetails>
              </Accordion>
              {/* Estudios y analisis */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Estudios</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {(!!DetalleConsulta && !!DetalleConsulta.Estudios) ? (
                    <>
                      <TextArea
                        value={DetalleConsulta.Estudios.Descripcion}
                        placeholder="Descripcion"
                        style={{
                          margin: ".5% 0",
                          fontFamily: "sans-serif",
                        }}
                        name=""
                        id=""
                        cols="30"
                        autoSize
                        readOnly={true}
                      />

                      <div>
                        <p style={{ fontWeight: "500" }}>Lista de archivos</p>

                        {DetalleConsulta.Documentos.map((documento) => (
                          <div
                            style={{ margin: "1% 0" }}

                          >
                            {documento.Multimedia.substring(
                              documento.Multimedia.toString().length,
                              documento.Multimedia.toString().length - 3
                            ) === "jpeg" ||
                              documento.Multimedia.substring(
                                documento.Multimedia.toString().length,
                                documento.Multimedia.toString().length - 3
                              ) === "png" ||
                              documento.Multimedia.substring(
                                documento.Multimedia.toString().length,
                                documento.Multimedia.toString().length - 3
                              ) === "jpg" ? (
                              <Row
                                className="ListaDetalleEsAna"

                              >
                                <Image
                                  style={{ width: "50px", height: "50px" }}
                                  src={"https://" + documento.Multimedia}
                                  alt=""
                                />
                                <div
                                  style={{
                                    width: "60%",
                                    height: "20px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}

                                >
                                  <span>{documento.Nombre}</span>
                                </div>
                                <div></div>
                              </Row>
                            ) : documento.Multimedia.substring(
                              documento.Multimedia.toString().length,
                              documento.Multimedia.toString().length - 3
                            ) === "pdf" ? (
                              <Row
                                className="ListaDetalleEsAna"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  window.open(
                                    "https://" + documento.Multimedia,
                                    "_blank"
                                  );
                                }}
                              >
                                <img
                                  style={{ width: "7%", marginRight: "3px" }}
                                  src={Icono[22]}
                                />
                                <div
                                  style={{
                                    width: "60%",
                                    height: "20px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <span>{documento.Nombre}</span>
                                </div>
                                <div></div>
                              </Row>
                            ) : (
                              <Row
                                className="ListaDetalleEsAna"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  window.open(
                                    "https://" + documento.Multimedia,
                                    "_blank"
                                  );
                                }}
                              >
                                <OndemandVideo sx={{ color: "#148F9F" }} />
                                <div
                                  style={{
                                    width: "60%",
                                    height: "20px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <span>{documento.Nombre}</span>
                                </div>
                                <div></div>
                              </Row>
                            )}
                          </div>
                        ))}


                      </div>
                    </>
                  ) : (
                    <p style={{ textAlign: "center" }}>Sin información</p>
                  )}
                </AccordionDetails>
              </Accordion>
              {/* Diagnóstico clínico */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Diagnóstico clínico</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextArea
                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Diagnostico : ""}
                    placeholder="Diagnóstico clínico"
                    style={{
                      margin: ".5% 0",
                      fontFamily: "sans-serif",
                    }}
                    name=""
                    id=""
                    cols="30"
                    autoSize
                    readOnly={true}
                  />
                  {/* <div style={{ position: "relative" }}>
                  <Autosuggest
                    suggestions={Diagnostico}
                    onSuggestionsFetchRequested={
                      onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      onSuggestionsClearRequested
                    }
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    onSuggestionSelected={eventEnter}
                  />
                </div> */}
                </AccordionDetails>
              </Accordion>
              {/* Plan */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Plan</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextAreaAntd
                    label="Plan médico"
                    id="plan_medico"
                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Plan : ""}
                    placeholder="Plan"
                    style={{
                      // margin: ".5% 0",
                      marginBottom: ".5%",
                      fontFamily: "sans-serif",
                    }}
                    columns="30"
                    autoSize={true}
                    vacio={false}
                    readOnly={true}
                  />
                  <h6>Receta</h6>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      gap: "1%",
                    }}
                  >
                  </div>
                  {/* {DetalleConsulta.Receta.Partidas} */}
                  {/* {RowsReceta.length > 0 ? (
                    <Table columns={columns} dataSource={RowsReceta} />
                  ) : (
                    <p style={{ textAlign: "center" }}>Sin información</p>
                  )}

                  <hr
                    style={{
                      width: "100%",
                      border: "1px solid #148f9f",
                      opacity: "100%",
                      borderRadius: "5rem",
                    }}
                  /> */}
                  {!!DetalleConsulta && DetalleConsulta.Receta.map((receta, index) => (
                    <>
                      {receta.partidas.length > 0 ? (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Receta {index + 1} - Folio: {receta.folio}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Table columns={columns} dataSource={receta.partidas} scroll={{ x: 5 }} />
                          </AccordionDetails>
                        </Accordion>

                      ) : (
                        <p style={{ textAlign: "center" }}>Sin información</p>
                      )}
                    </>
                  ))}


                  <hr
                    style={{
                      width: "100%",
                      border: "1px solid #148f9f",
                      opacity: "100%",
                      borderRadius: "5rem",
                    }}
                  />

                  <Row style={{ justifyContent: "start", margin: "2% 0" }}>
                    <h4 style={{ margin: "1% 0" }}>Ordenes</h4>
                  </Row>
                  {!!DetalleConsulta && DetalleConsulta.Orden.map((orden, index) => (
                    <>
                      {DetalleConsulta.Orden.length > 0 ? (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{orden.tipoOrden === 1 ? `Laboratorio` : `Interconsulta`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {
                              orden.tipoOrden === 1 ? (
                                <TextAreaAntd
                                  placeholder="Descripción"
                                  value={orden.descripcion}
                                  style={{
                                    marginBottom: ".5%",
                                    fontFamily: "sans-serif",
                                  }}
                                  columns="30"
                                  autoSize={true}
                                  true={true}
                                />
                              ) : (

                                <>
                                  {/* {
                                      contactList.forEach(user => {
                                        if (user.IdContacto === orden.idMedico) {
                                          setDatosMedico({ id: user.IdContacto, nombre: `${user.Nombre} ${user.ApellidoPaterno} ${user.ApellidoMaterno}`, imagen: user.Imagen_url })
                                        }
                                      })
                                    } */}
                                  {/* <Row style={{ justifyContent: "space-between", margin: "2% 0" }}>
                                      <Button
                                        type="button"
                                        onClick={() => addOrdenInter()}
                                        style={{
                                          // width: '60%',
                                          background: "#148f9f",
                                          border: "0",
                                          color: "white",
                                          display: "flex",
                                          alignItems: "center"
                                        }}

                                      >
                                        Guardar interconsulta
                                      </Button>
                                    </Row> */}

                                  {/* <SelectAntd
                                        label="Médico:"
                                        value={
                                          !!DatosMedico.id
                                            ? {
                                              value: "",
                                              label: (
                                                <div style={{ display: "flex", gap: "1" }}>
                                                  <img
                                                    className="ImgMedicoSelect"
                                                    src={
                                                      !!DatosMedico.imagen
                                                        ? `https://${DatosMedico.imagen}`
                                                        : ""
                                                    }
                                                  />{" "}
                                                  <div style={{ marginLeft: "1%" }}>
                                                    {DatosMedico.nombre}
                                                  </div>
                                                </div>
                                              ),
                                            }
                                            : null
                                        }
                                        style={{
                                          width: "100%",
                                          margin: "1% 0",
                                        }}
                                        placeholder="Selecciona un médico"
                                        onClick={() => {
                                          OpenModalContactos(true);
                                        }}
                                        setStateError={setMensajeDoctorSelect}
                                        valueError={MensajeDoctorSelect}
                                        textError="Debe seleccionar un médico"
                                        readOnly={true}
                                        open={false}
                                        vacio={false}
                                      /> */}

                                  {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <b>Datos del médico</b>
                                      </div> */}
                                  {/* <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                        <InputAntd
                                          label="Nombre:"
                                          type=""
                                          placeholder="Escriba un nombre"
                                          value={NombreMedico}
                                          setStateValue={setNombreMedico}
                                          vacio={false}
                                          // style={{ width: "100%" }}
                                          styleDiv={{ width: "100%" }}
                                          setStateError={setMensajeNombreMedico}
                                          textError={["Debe escribir un nombre", ""]}
                                          valueError={MensajeNombreMedico}
                                          disabled={InputDisabled}
                                        />

                                        <InputAntd
                                          label="Apellido Paterno:"
                                          type=""
                                          placeholder="Escriba un apellido paterno"
                                          value={ApellidoPMedico}
                                          setStateValue={setApellidoPMedico}
                                          vacio={false}
                                          // style={{ width: "100%" }}
                                          setStateError={setMensajeApellidoPMedico}
                                          textError={["Debe escribir un apellido paterno", ""]}
                                          valueError={MensajeApellidoPMedico}
                                          styleDiv={{ width: "100%" }}
                                          disabled={InputDisabled}
                                        />

                                        <InputAntd
                                          label="Apellido Materno:"
                                          type=""
                                          placeholder="Escriba un apellido materno"
                                          value={ApellidoMMedico}
                                          setStateValue={setApellidoMMedico}
                                          vacio={true}
                                          styleDiv={{ width: "100%" }}
                                          disabled={InputDisabled}
                                        />

                                      </div>
                                      <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                        <InputAntd
                                          label="Especialidad:"
                                          type=""
                                          placeholder="Escriba una Especialidad"
                                          value={EspecialidadMedico}
                                          setStateValue={setEspecialidadMedico}
                                          vacio={false}
                                          setStateError={setMensajeEspecialidadMedico}
                                          textError={["Debe escribir una especialidad", ""]}
                                          valueError={MensajeEspecialidadMedico}
                                          styleDiv={{ width: "100%" }}
                                          // disabled={!!DoctorSelect || CheckNuevo ? false : true}
                                          disabled={!!DoctorSelect ? false : true}
                                        />

                                        <InputAntd
                                          label="Celular:"
                                          type=""
                                          placeholder="Escriba un celular"
                                          value={CelularMedico}
                                          setStateValue={setCelularMedico}
                                          vacio={true}
                                          styleDiv={{ width: "100%" }}
                                          disabled={InputDisabled}
                                        />

                                        <InputAntd
                                          label="Email:"
                                          type=""
                                          placeholder="Escriba un email"
                                          value={EmailMedico}
                                          setStateValue={setEmailMedico}
                                          vacio={true}
                                          styleDiv={{ width: "100%" }}
                                          disabled={InputDisabled}
                                        />

                                      </div> */}

                                  <b>Datos interconsulta</b>
                                  <TextAreaAntd
                                    label="Motivo:"
                                    value={orden.motivo}
                                    placeholder="Escriba un motivo"
                                    style={{
                                      margin: ".5% 0",
                                      fontFamily: "sans-serif",
                                    }}
                                    columns="30"
                                    autoSize={true}
                                    readOnly={true}
                                  />

                                  <TextAreaAntd
                                    label="Diagnóstico:"
                                    value={orden.diagnostico}
                                    placeholder="Escriba un diagnóstico"
                                    style={{
                                      margin: ".5% 0",
                                      fontFamily: "sans-serif",
                                    }}
                                    columns="30"
                                    autoSize={true}
                                    readOnly={true}
                                  />
                                </>
                              )
                            }
                          </AccordionDetails>
                        </Accordion>

                      ) : (
                        <p style={{ textAlign: "center" }}>Sin información</p>
                      )}
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
              {/* Recomendaciones */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Indicaciones</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextArea
                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Recomendaciones : ""}
                    placeholder="Indicaciones"
                    style={{
                      margin: ".5% 0",
                      fontFamily: "sans-serif",
                    }}
                    name=""
                    id=""
                    cols="30"
                    autoSize
                    readOnly={true}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          )}

        </div>
      </ModalNotaMedica>

      {/* <ModalAddNota isOpen={isOpenModalAddNota} closeModal={CloseModalAddNota} styleDet={styleDet}> */}
      <ModalAddNota isOpen={AccionesModal.isOpenModalAddNota} closeModal={AccionesModal.CloseModalAddNota} styleDet={styleDet}>
        <div className="topCloseModalNotas1">
          Datos de consulta
          <CloseIcon style={{ cursor: "pointer", fontSize: 20 }} onClick={() => {
            setMotivoConsulta("");
            setTipoConsulta(null);
            AccionesModal.CloseModalAddNota(true);

          }} />

        </div>
        <div className="ModalNotasMed2" style={{ background: "#eaeef0" }}>
          <SelectAntd
            value={TipoConsulta}
            style={{ width: "100%", margin: "2% 0" }}
            placeholder="selecciona un tipo"
            options={[{ value: "1", label: "Primera vez" }, { value: "2", label: "Seguimiento" }]}
            setStateValue={setTipoConsulta}
            setStateError={setMensajeTipoConsulta}
            valueError={MensajeTipoConsulta}
            textError="Debe elegir un tipo"
          />
          <TextAreaAntd
            value={MotivoConsulta}
            placeholder="Motivo de consulta"
            style={{
              margin: "2% 0",
              fontFamily: "sans-serif",
            }}
            columns="30"
            autoSize={true}
            setStateValue={setMotivoConsulta}
            setStateError={setMensajeMotivoConsulta}
            valueError={MensajeMotivoConsulta}
            textError={["Debe escribir un motivo de consulta"]}
            vacio={true}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                background: "#148F9F",
                border: "#148F9F",
                color: "white"
              }}
              onClick={() => {
                if (!!TipoConsulta && !!MotivoConsulta) {
                  // setopcionModal(1);
                  AccionesModal.CloseModalAddNota(true);
                  navigate("/app/NuevaConsulta",
                    {
                      //  replace: false,
                      state: {
                        id: DatosContacto.id,
                        imagen: DatosContacto.imagen,
                        nombre: DatosContacto.nombre,
                        motivo: MotivoConsulta,
                        tipoConsulta: TipoConsulta,
                        idCita: "",
                      },
                    });
                } else {
                  if (!!!TipoConsulta) {
                    setMensajeTipoConsulta("false");
                  }

                  if (!!!MotivoConsulta) {
                    setMensajeMotivoConsulta("false");
                  }
                  AccionesMensaje.setOpen(true);
                  AccionesMensaje.setTypeMessage("warning");
                  AccionesMensaje.setMessage("Faltan campos por completar");
                }

              }}>Continuar</Button>
          </div>
        </div>
      </ModalAddNota>

      <ModalResumenMedico isOpen={IsOpenResumenMedico} closeModal={ClosedResumenMedico} optionClose={false} styleDet={styleDet}>
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          Resumen médico
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => {
            ClosedResumenMedico(true);
            OpenModalNotasMed();
            setResumenMedico("");
          }} />

        </div>
        <div className="ModalNotasResumenMed">

          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <TextAreaAntd
              label=""
              value={ResumenMedico}
              placeholder="Resumen médico"
              style={{
                width: "100%",
                marginBottom: ".5%",
                fontFamily: "sans-serif",
              }}
              styleDiv={{ width: "100%" }}
              columns="30"
              autoSize={true}
              setStateValue={setResumenMedico}
              setStateError={setMensajeResumenMedico}
              valueError={MensajeResumenMedico}
              textError={["Debe escribir un resumen médico"]}
              vacio={true}
            />
          </div>
          {loaderResumenMedico ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={40} sx={{ color: "#148f9f" }} />
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                style={{
                  background: "white",
                  border: "1",
                  borderColor: "#148F9F",
                  color: "#148F9F"
                }}
                onClick={() => {
                  vistaPreviaResumenMedico();
                }}>Vista previa</Button>
              <Button
                style={{
                  background: "#148F9F",
                  border: "#148F9F",
                  width: "30%",
                  color: "white"
                }}
                onClick={() => {
                  if (!!ResumenMedico) {
                    ClosedResumenMedico(true);
                    addResumenMedico();

                  } else {
                    setMensajeResumenMedico("false");
                    AccionesMensaje.setOpen(true);
                    AccionesMensaje.setTypeMessage("warning");
                    AccionesMensaje.setMessage("Faltan campos por completar");
                  }
                }}>Crear</Button>

            </div>
          )}
        </div>
      </ModalResumenMedico>

    </>
  );
}

export default ExpedienteNotasMedicas;
