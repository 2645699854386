import { AttachFile, Cancel, MicNone, Send } from "@mui/icons-material";
import { Image } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState, useRef, useEffect } from "react";
import { BarLoader } from "react-spinners";
import "./styles/bottom_bar_chat.scss"

const BottomBarChat = ({ FilePreview, setFilePreview, loaderSendMessage, messageToSend, setMessageToSend, setMessageToSendTemp,
    sendMessage, handleFileSelect, setOpen, setTypeMessage, setMessage }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);
    const mediaRecorderRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const audioChunksRef = useRef([]);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const canvasRef = useRef(null);
    const animationRef = useRef(null);
    const actionRef = useRef(null);

    // Función para formatear el tiempo en 00:00
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };

    // Iniciar la grabación
    const startRecording = async () => {
        try {

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaStreamRef.current = stream;
            const options = { mimeType: 'audio/mp4; codecs=mp4a.40.2' };
            mediaRecorderRef.current = new MediaRecorder(stream, options);

            // Configurar el contexto de audio y el analizador
            audioContextRef.current = new AudioContext();
            const source = audioContextRef.current.createMediaStreamSource(stream);
            analyserRef.current = audioContextRef.current.createAnalyser();
            source.connect(analyserRef.current);
            analyserRef.current.fftSize = 256;
            const bufferLength = analyserRef.current.frequencyBinCount;
            dataArrayRef.current = new Uint8Array(bufferLength);

            // Configurar el MediaRecorder
            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };
            mediaRecorderRef.current.onstop = () => {
                console.log("Stop audio");
                // if (actionRef.current === "stop")
                console.log(actionRef.current);
                if (actionRef.current === "stop") {
                    const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp4" });
                    cancelAnimationFrame(animationRef.current); // Detener la animación de las ondas
                    sendMessage("3", audioBlob);
                } else {
                    setOpen(true);
                    setTypeMessage("warning");
                    setMessage("Audio cancelado");
                }

                audioChunksRef.current = [];
                actionRef.current = null;
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
            setRecordingTime(0); // Reiniciar el tiempo de grabación
            drawWaveform(); // Iniciar la visualización de ondas
        } catch (err) {
            throw new Error("Error al acceder al micrófono:", err);
        }
    };

    // Detener para enviar la grabación
    const stopRecording = () => {
        actionRef.current = "stop";
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop(); // Detener la grabación
            if (audioContextRef.current) {
                audioContextRef.current.close(); // Cerrar el contexto de audio
            }
        }
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach((track) => track.stop()); // Detener el MediaStream
        }
        setIsRecording(false);

    };

    // Detener para cancelar la grabación
    const cancelRecording = () => {
        actionRef.current = "cancel";
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop(); // Detener la grabación
        }
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach((track) => track.stop()); // Detener el MediaStream
        }
        setIsRecording(false);
    };

    // Dibujar las ondas de audio
    const drawWaveform = () => {
        const canvas = canvasRef.current;
        if (!canvas) return; // Verificar que el canvas exista

        const ctx = canvas.getContext("2d");
        const width = canvas.width;
        const height = canvas.height;

        const draw = () => {
            animationRef.current = requestAnimationFrame(draw);
            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            ctx.fillStyle = "rgb(200, 200, 200)";
            ctx.fillRect(0, 0, width, height);
            ctx.lineWidth = 2;
            ctx.strokeStyle = "rgb(0, 0, 0)";
            ctx.beginPath();

            const sliceWidth = (width * 1.0) / dataArrayRef.current.length;
            let x = 0;

            for (let i = 0; i < dataArrayRef.current.length; i++) {
                const v = dataArrayRef.current[i] / 128.0;
                const y = (v * height) / 2;

                if (i === 0) {
                    ctx.moveTo(x, y);
                } else {
                    ctx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            ctx.lineTo(width, height / 2);
            ctx.stroke();
        };

        draw();
    };

    // Actualizar el tiempo de grabación
    useEffect(() => {
        let interval;
        if (isRecording) {
            interval = setInterval(() => {
                setRecordingTime((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRecording]);

    // Iniciar la animación del canvas cuando isRecording es true
    useEffect(() => {
        if (isRecording) {
            drawWaveform();
        } else {
            cancelAnimationFrame(animationRef.current); // Detener la animación cuando no se está grabando
        }
    }, [isRecording]);

    return (


        <div className="panel-escritura">
            {loaderSendMessage && (<BarLoader color="#148f9f" width={"100%"} />)}
            {FilePreview !== "" && <div style={{ display: "flex" }}>
                <Image
                    width={100}
                    src={FilePreview}
                />
                <button onClick={() => { setFilePreview(""); document.getElementById("ChatFile").value = ""; }}>X</button>
            </div>}

            <div className={!isRecording ? "bottombar" : "bottombar-audio"}>
                {!isRecording && (
                    <>
                        <label htmlFor="ChatFile">
                            <AttachFile htmlColor="rgb(96, 99, 102)" style={{ cursor: "pointer" }} />

                            <input accept="image/png,image/jpeg,application/pdf" style={{ display: "none" }} id="ChatFile" type="file" onChange={handleFileSelect} />
                        </label>
                        <form className="textarea">
                            <TextArea
                                name="message"
                                type="text"
                                placeholder="Escribir Mensaje"
                                value={messageToSend}
                                autoSize
                                onChange={(e) => { setMessageToSend(e.target.value); setMessageToSendTemp(e.target.value); }}
                            />
                        </form>
                    </>
                )}
                <div style={{ display: "flex", justifyContent: "right", alignItems: "center", margin: ".5%", gap: "1%" }}>
                    {(messageToSend || FilePreview) && (
                        <Send onClick={(e) => {
                            if (!!FilePreview) {
                                sendMessage("2");
                            } else {
                                sendMessage("0");
                            }
                        }} htmlColor="#148f9f" style={{ cursor: "pointer" }} fontSize="medium" />
                    )}
                </div>
                {isRecording && (
                    <div style={{ display: 'flex', gap: "10px" }}>
                        <p>{formatTime(recordingTime)}</p>
                        <canvas ref={canvasRef} width="80%" height="20vh"></canvas>
                    </div>
                )}
                {isRecording && (
                    <Cancel onClick={cancelRecording} htmlColor="rgb(226, 75, 75)" style={{ cursor: "pointer", marginRight: "10px" }} />
                )}
                {(!messageToSend && !FilePreview) && (
                    <>
                        {!isRecording ? (
                            <MicNone onClick={startRecording} htmlColor="rgb(96, 99, 102)" style={{ cursor: "pointer" }} />
                        ) : (
                            <Send onClick={stopRecording} htmlColor="#148f9f" style={{ cursor: "pointer" }} fontSize="medium" />
                        )}
                    </>
                )}

            </div>
        </div>
    );
};

export default BottomBarChat;