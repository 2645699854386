import React from 'react'
import { useState } from 'react';
import { Stack } from '@mui/material'
import NavbarNuevo from '../componentes/GeneralComponents/Navbar_Nuevo'
import ButtonAntd from '../componentes/GeneralComponents/ButtonAntd';
import { UseModal } from '../hooks/UseModal';
import { Avatar, Empty, Form, Image, Input, Row, Select } from 'antd';
import { Close, Search } from '@mui/icons-material';
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import { getOrdenes_request, saveOrdenes_request } from '../services/ordenes_services';
import SnackBar from '../componentes/GeneralComponents/Snackbar';
import { useEffect } from 'react';
import ModalDetalle from '../componentes/GeneralComponents/ModalDetalle';
import { Content } from 'antd/es/layout/layout';
import "./styles/Ordenes.scss"
import InputAntd from '../componentes/GeneralComponents/InputAntd';
import { Label, LeyendaError } from '../componentes/GeneralComponents/Formularios';
import TextAreaAntd from '../componentes/GeneralComponents/TextAreaAntd';
import { getContacts_request } from '../services/contactos_services';
import TextArea from 'antd/es/input/TextArea';
import AddLista from "../componentes/GeneralComponents/ModListDeContactos";
import OrdenesCard from '../componentes/Ordenes/ordenesCard';
import LoaderModals from '../componentes/GeneralComponents/Loader_modals';
import { Loader } from '../componentes/GeneralComponents/Loader';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

export default function Ordenes() {
    const location = useLocation();
    const [isOpenAddOrden, OpenAddOrden, CloseAddOrden] = UseModal(false);

    const [contactList, setContactList] = useState([]);
    const [contactListFilter, setContactListFilter] = useState([]);
    const [DatosContacto, setDatosContacto] = useState({ id: "", nombre: "", imagen: "" });

    const [OrdenesList, setOrdenesList] = useState([]);
    const [OrdenesListFilter, setOrdenesListFilter] = useState([]);

    const [MedicosList, setMedicosList] = useState([]);
    const [MedicosListFilter, setMedicosListFilter] = useState([]);

    const [TipoOrden, setTipoOrden] = useState(null);
    const [AccionModal, setAccionModal] = useState(0);

    const [DatosMedico, setDatosMedico] = useState({ id: "", nombre: "", imagen: "" });

    const [loader, setLoader] = useState(false);
    const [loaderDetalle, setLoaderDetalle] = useState(false);

    // ORDEN DE ESTUDIOS DE LABORATORIO
    const [ListaOrdenLabs, setListaOrdenLabs] = useState([]);
    const [OrdenLabTest, setOrdenLabTest] = useState("");

    // INTERCONSULTA
    const [ListaOrdenInter, setListaOrdenInter] = useState([]);
    const [InterconsultaAdjunta, setInterconsultaAdjunta] = useState(0);
    const [DoctorSelect, setDoctorSelect] = useState(null);
    const [CheckNuevo, setCheckNuevo] = useState(false);
    const [NombreMedico, setNombreMedico] = useState("");
    const [ApellidoPMedico, setApellidoPMedico] = useState("");
    const [ApellidoMMedico, setApellidoMMedico] = useState("");
    const [CelularMedico, setCelularMedico] = useState("");
    const [EmailMedico, setEmailMedico] = useState("");
    const [EspecialidadMedico, setEspecialidadMedico] = useState("");

    const [EspecialidadesOptions, setEspecialidadesOptions] = useState("");
    const [MotivoInterconsulta, setMotivoInterconsulta] = useState("");
    const [DiagnosticoInterconsulta, setDiagnosticoInterconsulta] = useState("");
    const [TratamientoInterconsulta, setTratamientoInterconsulta] = useState("");

    // MANEJA DE MENSAJES
    const [open, setOpen] = useState(false);
    const [typeMessage, setTypeMessage] = useState("");
    const [message, setMessage] = useState("");

    const [MensajeDoctorSelect, setMensajeDoctorSelect] = useState("true");
    const [MensajeNombreMedico, setMensajeNombreMedico] = useState("true");
    const [MensajeApellidoPMedico, setMensajeApellidoPMedico] = useState("true");
    const [MensajeEspecialidadMedico, setMensajeEspecialidadMedico] = useState("true");
    const [MensajeMotivoInterconsulta, setMensajeMotivoInterconsulta] = useState("true");

    const [MensajeOrdenLabTest, setMensajeOrdenLabTest] = useState("true");

    async function getOrdenes() {
        setLoader(true);
        let response = await getOrdenes_request();
        if (response.ok) {
            // console.log(response.data);
            setOrdenesList(response.data);
            setOrdenesListFilter(response.data);
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoader(false);
    }

    async function saveOrden() {
        setLoaderDetalle(true);
        // console.log(TipoOrden);
        // let data = new FormData();
        let data = "";
        if (TipoOrden === "0") {
            // data.append("idContactoM", [{
            //     id: DatosMedico.id,
            //     nombre: NombreMedico,
            //     apellidoPaterno: ApellidoPMedico,
            //     apellidoMaterno: ApellidoMMedico,
            //     celular: CelularMedico,
            //     email: EmailMedico,
            //     especialidad: EspecialidadMedico,
            // }]);
            // data.append("tipoOrden", TipoOrden);
            // data.append("mOrden", MotivoInterconsulta);
            // data.append("diagnostico", DiagnosticoInterconsulta);
            // data.append("tratamiento", TratamientoInterconsulta);
            data = {
                Order: {
                    idContactoM: [
                        {
                            id: DoctorSelect,
                            nombre: NombreMedico,
                            apellidoPaterno: ApellidoPMedico,
                            apellidoMaterno: ApellidoMMedico,
                            celular: CelularMedico,
                            email: EmailMedico,
                            especialidad: EspecialidadMedico,
                        }
                    ],
                    tipoOrden: TipoOrden,
                    mOrden: MotivoInterconsulta,
                    diagnostico: DiagnosticoInterconsulta,
                    tratamiento: TratamientoInterconsulta,
                },
                IdUsuario: DatosContacto.id
            };
        } else {
            // data.append("tipoOrden", TipoOrden);
            // data.append("descripcion", OrdenLabTest);
            data = {
                Order: {
                    tipoOrden: TipoOrden,
                    descripcion: OrdenLabTest,
                },
                IdUsuario: DatosContacto.id
            };
        }
        // console.log(data);
        // return;
        let response = await saveOrdenes_request(data);
        if (response.ok) {
            getOrdenes();
            setOpen(true);
            setTypeMessage("success");
            setMessage("Orden creada");
            cleanInputsOrden();
            CloseAddOrden(true);

        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderDetalle(false);
    }

    async function getContactos() {
        let response = await getContacts_request();
        if (response.ok) {
            // console.log(response.data);
            let medicos_filter = [];
            let pacientes_filter = [];
            response.data.forEach((element) => {
                if (element.Type === 1 && element.YgiaUser === "Con Ygia") {
                    // medicos[0].options.push({ key: element.IdContacto, value: element.IdContacto, label: <div style={{ display: "flex", gap: "1" }}><img className="ImgMedicoSelect" src={!!element.Imagen_url ? `https://${element.Imagen_url}` : ""} /> <div style={{ marginLeft: "1%" }}>{`${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno} - ${element.YgiaUser}`}</div></div> });
                    medicos_filter.push(element);
                } else if (element.Type !== 1 && element.Type !== 4) {
                    pacientes_filter.push({
                        usuario: `${element.Nombre} ${element.ApellidoPaterno} ${!!element.ApellidoMaterno ? element.ApellidoMaterno : ""}`,
                        imagen: `${!!element.Imagen_url ? element.Imagen_url : ""}`,
                        id: `${element.IdContacto}`,
                    });
                    // pacientes_filter.push(element);
                }
            });
            // console.log(medicos_filter);
            setMedicosList(medicos_filter);
            setMedicosListFilter(medicos_filter);
            setContactList(pacientes_filter);
            setContactListFilter(pacientes_filter);
        } else {
        }
    }

    function filterList(text) {
        var chars = {
            á: "a",
            é: "e",
            í: "i",
            ó: "o",
            ú: "u",
            à: "a",
            è: "e",
            ì: "i",
            ò: "o",
            ù: "u",
            ñ: "n",
            Á: "A",
            É: "E",
            Í: "I",
            Ó: "O",
            Ú: "U",
            À: "A",
            È: "E",
            Ì: "I",
            Ò: "O",
            Ù: "U",
            Ñ: "N",
        };
        var expr = /[áàéèíìóòúùñ]/gi;
        let filter = [];

        OrdenesListFilter.forEach((receta) => {
            if (
                receta.Contacto.replace(expr, function (e) {
                    return chars[e];
                })
                    .toLowerCase()
                    .indexOf(
                        text.target.value
                            .replace(expr, function (e) {
                                return chars[e];
                            })
                            .toLowerCase()
                    ) > -1 ||
                receta.folio
                    .replace(expr, function (e) {
                        return chars[e];
                    })
                    .toLowerCase()
                    .indexOf(
                        text.target.value
                            .replace(expr, function (e) {
                                return chars[e];
                            })
                            .toLowerCase()
                    ) > -1
            ) {
                filter.push(receta);
            }
        });
        setOrdenesList(filter);
    }

    function filterOptions(input, option) {
        // console.log(input);
        // console.log(option);
        var chars = {
            "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
            "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
            "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
            "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
        }
        var expr = /[áàéèíìóòúùñ]/ig;
        return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
    }

    function filterSort(optionA, optionB) {
        // console.log(optionA);
        // console.log(optionB);
        var chars = {
            "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
            "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
            "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
            "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
        }
        var expr = /[áàéèíìóòúùñ]/ig;
        return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
    }

    function selectMedico(e) {
        let array_espe = [];
        setDoctorSelect(e);
        MedicosList.forEach(element => {
            if (element.IdContacto === e) {
                setNombreMedico(element.Nombre);
                setApellidoPMedico(element.ApellidoPaterno);
                setApellidoMMedico(element.ApellidoMaterno);
                setCelularMedico(element.Celular);
                setEmailMedico(element.Email);
                element.Especialidades.forEach(especialidad => {
                    array_espe.push({
                        label: especialidad,
                        value: especialidad
                    })
                });
                // setEspecialidadMedico(element.Especialidad);
            }
        });
        setEspecialidadesOptions(array_espe);
    }

    function filterContacts(text) {
        // console.log(text.target.value);
        // console.log(solicitudesSearch);
        var chars = {
            á: "a",
            é: "e",
            í: "i",
            ó: "o",
            ú: "u",
            à: "a",
            è: "e",
            ì: "i",
            ò: "o",
            ù: "u",
            ñ: "n",
            Á: "A",
            É: "E",
            Í: "I",
            Ó: "O",
            Ú: "U",
            À: "A",
            È: "E",
            Ì: "I",
            Ò: "O",
            Ù: "U",
            Ñ: "N",
        };
        var expr = /[áàéèíìóòúùñ]/gi;
        let filter = [];

        contactListFilter.forEach((contacto) => {
            if (
                contacto.usuario
                    .replace(expr, function (e) {
                        return chars[e];
                    })
                    .toLowerCase()
                    .indexOf(
                        text.target.value
                            .replace(expr, function (e) {
                                return chars[e];
                            })
                            .toLowerCase()
                    ) > -1
            ) {
                filter.push(contacto);
            }
        });

        setContactList(filter);
    }

    function cleanInputsOrden() {
        setTipoOrden(null);
        setDoctorSelect(null);
        setNombreMedico("");
        setApellidoPMedico("");
        setApellidoMMedico("");
        setCelularMedico("");
        setEmailMedico("");
        setEspecialidadMedico("");
        setMotivoInterconsulta("");
        setDiagnosticoInterconsulta("");
        setTratamientoInterconsulta("");
        setOrdenLabTest("");
    }


    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: location.pathname,
        });
        getOrdenes();
        getContactos();
    }, [])


    return (
        <Stack>
            <NavbarNuevo
                mensajesError={{
                    setOpen: setOpen,
                    setTypeMessage: setTypeMessage,
                    setMessage: setMessage,
                }}
                modulo={"Ordenes"}
                searchBar={
                    <div className="Encabezadorec">
                        <div className="espaciadorec">
                            <div>
                                <b>Órdenes</b>
                                <br />
                            </div>
                            <ButtonAntd
                                tipo={1}
                                style={{ width: "105px" }}
                                onClick={OpenAddOrden}
                                children={
                                    <Row style={{ gap: ".5rem" }}>
                                        <b>Nueva</b>
                                        <img style={{ width: "12px" }} src={Agregar} alt="" />
                                    </Row>
                                }
                            />
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "1%" }}>
                            <Input
                                prefix={<Search style={{ color: "#d7d7d7", width: "18px" }} />}
                                style={{ width: "45%" }}
                                placeholder="Paciente o folio"
                                type="text"
                                onChange={filterList}
                            />
                            {/* <div style={{ display: "flex", width: "100%" }}>
                                <img
                                    onClick={() => {
                                        // OpenFiltro(true);
                                        // setMensajeFechaInicio("true");
                                        // setMensajeFechaFin("true");
                                    }}
                                    style={{ width: "30px", cursor: "pointer" }}
                                    src={Filtro}
                                    alt=""
                                />
                                {FiltroActive && (
                                    <Button
                                        style={{
                                            background: "white",
                                            color: "#148F9F",
                                            border: "0",
                                            height: "30px",
                                            padding: "0 1%",
                                            margin: ".8% 0",
                                            // marginLeft: "3%",
                                            marginRight: "0",
                                        }}
                                        onClick={() => {
                                            setFiltroActive(false);
                                            setEstadoFiltro("Todos");
                                            setFechaInicio("");
                                            setFechaFin("");
                                            getRecetas();
                                        }}
                                    >
                                        <Cancel sx={{ color: "#148f9f" }} />
                                        Filtro
                                    </Button>
                                )}
                            </div> */}
                        </div>
                    </div>
                }
            />

            <Content>
                {loader ? (<Loader />) : (
                    <>
                        <OrdenesCard
                            OrdenesList={OrdenesList}
                        />
                        {OrdenesList.length === 0 && (
                            <Empty
                                description={
                                    <span style={{ color: "black" }}>Sin ordenes</span>
                                }
                            />
                            // <p className="noCitas">Sin citas</p>
                        )}
                    </>
                )}
            </Content>

            {/* MODAL AGREGAR ORDEN */}
            <ModalDetalle isOpen={isOpenAddOrden} closeModal={CloseAddOrden} optionClose={false}>
                {AccionModal === 1 && (
                    <>
                        <header
                            style={{
                                position: "sticky",
                                top: 0,
                                zIndex: 999,
                                width: "100%",
                                // background: "#148f9f",
                                // padding: "1% 2%",
                                // display: "flex",
                                // justifyContent: "space-between",
                                textAlign: "center"
                            }}
                        >
                            <div style={{ padding: "1% 2%", background: "#148f9f", display: "flex", justifyContent: "space-between" }}>
                                <p style={{ color: "#ffffff" }}>Nueva órden</p>
                                <Close
                                    style={{ fontSize: 20, cursor: "pointer", color: "white" }}
                                    onClick={() => {
                                        // CloseAddOrden(true);
                                        setAccionModal(0);
                                        cleanInputsOrden();
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Avatar size={60} src={
                                    <Image
                                        src={
                                            !!DatosContacto.imagen
                                                ? "https://" + DatosContacto.imagen
                                                : ""
                                        }
                                    />
                                } />
                            </div>
                            <h3>{DatosContacto.nombre}</h3>
                        </header>

                        <div className='ModalAddOrden'>
                            {loaderDetalle ? (<LoaderModals className={"ModalAddOrden"} />) : (
                                <Form onFinish={saveOrden} layout="vertical" autoComplete="off">
                                    <Form.Item
                                        name={"tipoOrden"}
                                        label={<Label >Tipo de orden:</Label>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Debe seleccionar un tipo de orden"
                                            },
                                        ]}
                                    >
                                        <Select
                                            id='tipoOrden'
                                            // defaultValue={"1"}
                                            value={TipoOrden}
                                            options={[{ label: "Laboratorio", value: "1" }, { label: "Interconsulta", value: "0" }]}
                                            onSelect={(e) => {
                                                setTipoOrden(e);
                                            }}
                                            placeholder="Seleccionar tipo"
                                        />
                                    </Form.Item>

                                    {TipoOrden === "0" && (
                                        <>
                                            <Form.Item
                                                name="contactoMedico"
                                                label={<Label >Médico:</Label>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Debe seleccionar un médico"
                                                    },
                                                ]}
                                            >

                                                <Select
                                                    placeholder="Selecciona un médico"
                                                    style={{
                                                        width: "100%",
                                                        margin: "1% 0",
                                                    }}
                                                    onSelect={selectMedico}
                                                    showSearch
                                                    filterOption={filterOptions}
                                                    filterSort={filterSort}
                                                    value={DoctorSelect}
                                                >
                                                    {MedicosList.map((medico) => (
                                                        <Select.Option key={medico.IdContacto} value={medico.IdContacto} label={`${medico.Nombre} ${medico.ApellidoPaterno} ${!!medico.ApellidoMaterno && medico.ApellidoMaterno}`}>
                                                            <div style={{ display: "flex", gap: "1" }}>
                                                                <Avatar src={
                                                                    !!medico.Imagen_url
                                                                        ? `https://${medico.Imagen_url}`
                                                                        : ""
                                                                } />
                                                                <div style={{ marginLeft: "1%" }}>
                                                                    {medico.Nombre} {medico.ApellidoPaterno} {!!medico.ApellidoMaterno && medico.ApellidoMaterno}
                                                                </div>
                                                            </div>
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>

                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <b>Datos del médico</b>
                                                {/* <Checkbox onChange={(e) => {
                                                            setInputDisabled(!InputDisabled);
                                                            setCheckNuevo(e.target.checked);
                                                            setDoctorSelect(null);
                                                            setNombreMedico("");
                                                            setApellidoPMedico("");
                                                            setApellidoMMedico("");
                                                            setCelularMedico("");
                                                            setEmailMedico("");
                                                            setMensajeDoctorSelect("true");
                                                        }}>Nuevo</Checkbox> */}
                                            </div>
                                            <div className='filaDatosMedicos'>
                                                <Form.Item
                                                    label={<Label>Nombre:</Label>}
                                                // name={"nombreMedico"}
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Debe escribir un nombre"
                                                //     },
                                                //     {
                                                //         type: "string",
                                                //         message: "Solo se permiten letras"
                                                //     }
                                                // ]}
                                                >

                                                    <InputAntd
                                                        // type=""
                                                        placeholder="Escriba un nombre"
                                                        value={NombreMedico}
                                                        setStateValue={setNombreMedico}
                                                        styleDiv={{ width: "100%" }}
                                                        disabled={true}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label={<Label>Apellido Paterno:</Label>}
                                                // name={"nombreMedico"}
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Debe escribir un nombre"
                                                //     },
                                                //     {
                                                //         type: "string",
                                                //         message: "Solo se permiten letras"
                                                //     }
                                                // ]}
                                                >
                                                    <InputAntd
                                                        placeholder="Escriba un apellido paterno"
                                                        value={ApellidoPMedico}
                                                        setStateValue={setApellidoPMedico}
                                                        styleDiv={{ width: "100%" }}
                                                        disabled={true}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label={<Label>Apellido Materno:</Label>}
                                                // name={"nombreMedico"}
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Debe escribir un nombre"
                                                //     },
                                                //     {
                                                //         type: "string",
                                                //         message: "Solo se permiten letras"
                                                //     }
                                                // ]}
                                                >
                                                    <InputAntd
                                                        // type=""
                                                        placeholder="Escriba un apellido materno"
                                                        value={ApellidoMMedico}
                                                        setStateValue={setApellidoMMedico}
                                                        vacio={true}
                                                        styleDiv={{ width: "100%" }}
                                                        disabled={true}
                                                    />
                                                </Form.Item>


                                            </div>
                                            <div className='filaDatosMedicos'>
                                                {/* <Form.Item
                                                    label={<Label>Especialidad:</Label>}
                                                // name={"nombreMedico"}
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Debe escribir un nombre"
                                                //     },
                                                //     {
                                                //         type: "string",
                                                //         message: "Solo se permiten letras"
                                                //     }
                                                // ]}
                                                >
                                                    <InputAntd
                                                        // label="Especialidad:"
                                                        // type=""
                                                        placeholder="Escriba una Especialidad"
                                                        value={EspecialidadMedico}
                                                        setStateValue={setEspecialidadMedico}
                                                        vacio={false}
                                                        setStateError={setMensajeEspecialidadMedico}
                                                        textError={["Debe escribir una especialidad", ""]}
                                                        valueError={MensajeEspecialidadMedico}
                                                        styleDiv={{ width: "100%" }}
                                                        // disabled={!!DoctorSelect || CheckNuevo ? false : true}
                                                        disabled={true}
                                                    />
                                                </Form.Item> */}

                                                <Form.Item
                                                    name={"especialidadOption"}
                                                    label={<Label >Especialidades:</Label>}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Debe seleccionar una especialidad"
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        id='especialidadOption'
                                                        // defaultValue={"1"}
                                                        value={TipoOrden}
                                                        options={EspecialidadesOptions}
                                                        onSelect={(e) => {
                                                            setEspecialidadMedico(e);
                                                        }}
                                                        placeholder="Seleccionar especialidad"
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label={<Label>Celular:</Label>}
                                                // name={"nombreMedico"}
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Debe escribir un nombre"
                                                //     },
                                                //     {
                                                //         type: "string",
                                                //         message: "Solo se permiten letras"
                                                //     }
                                                // ]}
                                                >
                                                    <InputAntd
                                                        // label="Celular:"
                                                        // type=""
                                                        placeholder="Escriba un celular"
                                                        value={CelularMedico}
                                                        setStateValue={setCelularMedico}
                                                        vacio={true}
                                                        styleDiv={{ width: "100%" }}
                                                        disabled={true}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label={<Label>Email:</Label>}
                                                // name={"nombreMedico"}
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Debe escribir un nombre"
                                                //     },
                                                //     {
                                                //         type: "string",
                                                //         message: "Solo se permiten letras"
                                                //     }
                                                // ]}
                                                >
                                                    <InputAntd
                                                        // label="Email:"
                                                        // type=""
                                                        placeholder="Escriba un email"
                                                        value={EmailMedico}
                                                        setStateValue={setEmailMedico}
                                                        vacio={true}
                                                        styleDiv={{ width: "100%" }}
                                                        disabled={true}
                                                    />
                                                </Form.Item>


                                            </div>

                                            <b>Datos interconsulta</b>
                                            <Form.Item
                                                label={<Label>Motivo:</Label>}
                                                name={"motivoInter"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Debe escribir un motivo"
                                                    }
                                                ]}
                                            >
                                                <TextArea
                                                    placeholder="Escriba un motivo"
                                                    value={MotivoInterconsulta}
                                                    style={{
                                                        marginBottom: ".5%",
                                                        fontFamily: "sans-serif",
                                                    }}
                                                    onChange={(e) => {
                                                        setMotivoInterconsulta(e.target.value);
                                                    }}
                                                    cols="30"
                                                    autoSize
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label={<Label>Diagnóstico sugerido:</Label>}
                                            // name={"nombreMedico"}
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: "Debe escribir un nombre"
                                            //     },
                                            //     {
                                            //         type: "string",
                                            //         message: "Solo se permiten letras"
                                            //     }
                                            // ]}
                                            >
                                                <TextAreaAntd
                                                    // label="Diagnóstico sugerido:"
                                                    value={DiagnosticoInterconsulta}
                                                    placeholder="Escriba un diagnóstico"
                                                    style={{
                                                        margin: ".5% 0",
                                                        fontFamily: "sans-serif",
                                                    }}
                                                    columns="30"
                                                    autoSize={true}
                                                    setStateValue={setDiagnosticoInterconsulta}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label={<Label>Tratamiento sugerido:</Label>}
                                            // name={"nombreMedico"}
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: "Debe escribir un nombre"
                                            //     },
                                            //     {
                                            //         type: "string",
                                            //         message: "Solo se permiten letras"
                                            //     }
                                            // ]}
                                            >
                                                <TextAreaAntd
                                                    // label="Tratamiento sugerido:"
                                                    value={TratamientoInterconsulta}
                                                    placeholder="Escriba un tratamiento"
                                                    style={{
                                                        margin: ".5% 0",
                                                        fontFamily: "sans-serif",
                                                    }}
                                                    columns="30"
                                                    autoSize={true}
                                                    setStateValue={setTratamientoInterconsulta}
                                                />
                                            </Form.Item>

                                        </>
                                    )}

                                    {TipoOrden === "1" && (
                                        <Form.Item
                                            label={<Label>Descripción:</Label>}
                                            name={"descripcionOrdLab"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Debe escribir una descripción"
                                                }
                                            ]}
                                        >
                                            <TextArea
                                                placeholder="Descripción"
                                                value={OrdenLabTest}
                                                style={{
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                onChange={(e) => {
                                                    setOrdenLabTest(e.target.value);
                                                }}
                                                cols="30"
                                                autoSize
                                            />
                                            {/* <TextAreaAntd
                                            placeholder="Descripción"
                                            value={OrdenLabTest}
                                            style={{
                                                marginBottom: ".5%",
                                                fontFamily: "sans-serif",
                                            }}
                                            columns="30"
                                            autoSize={true}
                                            setStateValue={setOrdenLabTest}
                                            setStateError={setMensajeOrdenLabTest}
                                            valueError={MensajeOrdenLabTest}
                                            textError={["Debe escribir una descripción"]}
                                            vacio={true}
                                        /> */}
                                        </Form.Item>
                                    )}
                                    <ButtonAntd
                                        htmlType="submit"
                                        style={{ width: "100%" }}
                                    >
                                        Guardar
                                    </ButtonAntd>
                                </Form>
                            )}

                        </div>
                    </>
                )}

                {AccionModal === 0 && (
                    <>
                        <header
                            style={{
                                position: "sticky",
                                top: 0,
                                zIndex: 999,
                                width: "100%",
                                background: "#148f9f",
                                padding: "1% 2%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <p style={{ color: "#ffffff" }}>Selecciona un Contacto</p>
                            <Close
                                style={{ fontSize: 20, cursor: "pointer", color: "white" }}
                                onClick={() => {
                                    CloseAddOrden(true);
                                    setAccionModal(0);
                                    cleanInputsOrden();
                                }}
                            />
                        </header>
                        <div className="ModalAddOrden">
                            {/* <div style={{ display: "flex", justifyContent: "end" }}>
                                <Button type="button" onClick={() => {
                                    ReactGA.event({
                                        category: "Consultas",
                                        action: "Contacto nuevo",
                                        label: "Boton para abrir form de nuevo contacto", // optional
                                        value: 99, // optional, must be a number
                                        nonInteraction: true, // optional, true/false
                                        transport: "xhr", // optional, beacon/xhr/image
                                    });
                                    ClosedSelectContacto(true);
                                    OpenAddContacto(true);
                                }}
                                    style={{
                                        width: "150px",
                                        backgroundColor: "#148f9f",
                                        color: "white"
                                    }}>Nuevo contacto</Button>
                            </div> */}
                            <p>Nombre</p>
                            <Input
                                placeholder="Buscar..."
                                type="search"
                                style={{ width: "100%", marginBottom: "10px" }}
                                onChange={filterContacts}
                            />
                            <AddLista
                                Lista={contactList}
                                funcionExtra={[6, setDatosContacto, setAccionModal]}
                                style={{ borderTop: "1px solid #d5d5d5" }}
                            />
                        </div>
                    </>
                )}
            </ModalDetalle>

            <SnackBar
                message={message}
                setOpen={setOpen}
                open={open}
                typeMessage={typeMessage}
            />
        </Stack>
    )
};