import React, {useEffect, useState} from "react";
import { Avatar, Image, Row, Button, Calendar, Modal } from "antd";
import {
    ExclamationCircleOutlined,
    EyeFilled,
    EyeOutlined,
} from "@ant-design/icons";
import {
    BorderColor,
    Cancel,
    CancelOutlined,
    CancelRounded,
    CheckCircleOutline,
    Edit,
    Search,
    AddOutlined,
    EditOutlined,
    DeleteForeverOutlined,
    RocketTwoTone
} from "@mui/icons-material";
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import ReactGA from "react-ga4";
import { useNavigate, useLocation } from "react-router-dom";

import "dayjs/locale/es";
import locale from "antd/es/calendar/locale/es_ES";

import { useSelector, useDispatch } from "react-redux";

import { SelectContact } from "../../../redux/contactos";
import { SetCitaId } from "../../../redux/citas";
import { showSnackbar } from "../../../redux/snackbarSlice";
import { ModalScreen, ShowModal, HideModal } from "../../../redux/appSlice";

import LoaderModals from "../../GeneralComponents/Loader_modals";
import PositionedMenu from "../../GeneralComponents/MenuVertical.js";
import InputAntd from "../../GeneralComponents/InputAntd";
import SelectAntd from "../../GeneralComponents/SelectAntd";
import TextAreaAntd from "../../GeneralComponents/TextAreaAntd";

import { detailCitas_request, getCitasFilter_request, reprogramarCita_request,confirmCitas_request,
  atenderCitas_request, deleteCitas_request, cancelCitas_request } from "../../../services/citas_services";

import Imag from '../../../Img/no-image.png';
import { getComboHorariosData, getHorariosDisponiblesByCitas } from "../../../utils/utils.js";
import { TiposCita } from "../../../pages/Citas";
import useCitasData from "../../../hooks/Query/useCitasData";
import useConfigCitasData from "../../../hooks/Query/useConfigCitasData";

dayjs.extend(isSameOrAfter);

const DetallesCitaContent = () => {
    const [loaderDetalle, setLoaderDetalle] = useState(true);
    const [loadingSchedule, setLoadingSchedule] = useState(false);
    const [infoCita, setInfoCita] = useState("");
    const [inputsEditable, setInputsEditable] = useState(true);
    const [citaId, setIdCita]= useState('');
    const [dataFichaId, setDataFichaId] = useState({});
    const [datosCita, setDatosCita] = useState({});
    const [fecha, setFechaCita] = useState();
    const [paciente, setPaciente] = useState('');
    const [horario, setHorario] = useState('');
    const [tipoCita, setTipoCita] = useState();
    const [estado, setEstado] = useState('');
    const [status, setStatus] = useState(0);
    const [creadoPor, setCreadoPor] = useState('');
    const [motivo, setMotivo] = useState('');
    const [comentario, setComentario] = useState('');
    const [comentarioCancelacion, setComentarioCancelacion] = useState('');
    const [horarios, setHorarios] = useState([]);
    const [ComboHorarioCita, setComboHorarioCita] = useState("true");
    const [EstatusModalEliminarCita, setEstatusModalEliminarCita] = useState(false);
    const [canceling, setIsCanceling] = useState(false);
    const [sending, setIsSending] = useState(false);
    const [motivoCancelacion, setMotivoCancelacion] = useState('');
    const [MensajeMotivoCancelacion, setMensajeMotivoCancelacion] = useState("true");

    const [modal, ModalEliminarCita] = Modal.useModal();

    const dispatch = useDispatch();
    const { selectedContact } = useSelector((state)=>state.contacts);
    const { selectedCitaId } = useSelector((state)=>state.appointments);
    const userInfo = useSelector((state)=>state.userInfo.usuario);
    // const { horariosConsulta } = useSelector((state)=> state.horarios);

    const navigate = useNavigate();
    const Citas = useCitasData();
    const { data: { horariosConsulta } } = useConfigCitasData();

    useEffect(()=>{
      return () => {
        dispatch(SelectContact({}));
        dispatch(SetCitaId(''));
      };
    }, []);

    useEffect(()=>{
      const loadCitaDetails = async() => {
        // await getHistoriaClinica(selectedContact.id);// TODO es necesario?
        let response = await detailCitas_request(selectedCitaId);
        
        if (response.ok) {
          
          let citaDetails = response.data;
          
          setHorario(citaDetails.HoraCita);
          
          setEstado(citaDetails.Estado);
          setStatus(citaDetails.Status);
          setCreadoPor(citaDetails.Creado);
          setMotivo(citaDetails.Comentarios);
          setComentario(citaDetails.Adicional);
          setComentarioCancelacion(citaDetails.CancelacionMotivo);
          setPaciente(citaDetails.Full_Name);
          
          let fechaCita=dayjs(response.data.FechaCita, 'DD-MM-YYYY', 'es');
          
          setFechaCita(fechaCita.format('YYYY-MM-DD'));
          setTipoCita(
            citaDetails.TipoCita === "Primera vez"
              ? "1"
              : citaDetails.TipoCita === "Urgencia"
                ? "0"
                : "2"
          );

          setLoaderDetalle(false);
        } else {
          dispatch(showSnackbar({
            show: true,
            text: response.mensaje,
            type: 'error'
          }));
          dispatch(HideModal(ModalScreen.DETALLE_CITA));
        }
      }
      loadCitaDetails();
    }, []);

    useEffect(()=>{
      const loadHorariosDisponibles = async(fecha)=>{
        if(fecha){
          let data = new FormData();
      
          data.append("FechaInicio", fecha);
          data.append("FechaFin", fecha);
  
          let today = dayjs(undefined).hour(0).minute(0).second(0).locale('es');
          
          let cita = dayjs(fecha).locale('es');
          
          if(cita.isSameOrAfter(today, 'day')){
            
            let response = await getCitasFilter_request(4, data);
            
            if(response.ok){
              const horariosDisponibles = getHorariosDisponiblesByCitas(response.data, horariosConsulta[cita.day()]);
              
              const comboData = getComboHorariosData(horariosDisponibles);
              setHorarios(comboData);
              setLoadingSchedule(false);
            }
            else{
              const horariosData = getComboHorariosData(horariosConsulta[cita.day()]);
              setHorarios(horariosData);
              setLoadingSchedule(false);
            }
          }
          else{
            setHorarios([]);
          }
        }
      }

      loadHorariosDisponibles(fecha)
    }, [horariosConsulta, fecha]);

    const reprogramarCita = async (e) => {
        e.preventDefault();
        setLoaderDetalle(true);
    
        if (!!tipoCita && !!horario && !!fecha) {
          let data = new FormData();
          data.append("Fecha", fecha);
          data.append("Hora", horario);
          data.append("Comentarios", motivo);
          data.append("Adicional", comentario);
          data.append("TipoCita", tipoCita);
          data.append("Status", status);

          let response = await reprogramarCita_request(data, selectedCitaId);
          if (response.ok) {
            Citas.refetch();
            dispatch(showSnackbar({
              show: true,
              text: 'Cita reagendada',
              type: 'success'
            }));

            dispatch(HideModal(ModalScreen.DETALLE_CITA));
          } else {
            dispatch(showSnackbar({
              show: true,
              text: response.mensaje,
              type: 'error'
            }));
            setLoaderDetalle(false);
          }
        } else {
          setLoaderDetalle(false);
          dispatch(showSnackbar({
            show: true,
            text: "Faltan campos por completar",
            type: "warning",
          }))
        }
      };

      const getCitaMenuOptions = () => {
        let buttons = [];
    
        if (userInfo && userInfo.Tipo === 1) {
          buttons.push({
            Name: 'Consultar',
            Icon: <AddOutlined color='secondary' />,
            Callback: () => dispatch(ShowModal(ModalScreen.ADD_NOTA_CITA))
          })
        }

        if (status !== 1) {
          buttons.push({
            Name: 'Confirmar',
            Icon: <CheckCircleOutline color="warning" />,
            Callback: () => confirmarCita()
          });
        }
    
        if (status !== 3) {
          buttons.push({
            Name: 'Atender',
            Icon: <CheckCircleOutline color="success" />,
            Callback: () => atenderCita()
          });
        }
    
        if (status !== 2) {
          buttons.push({
            Name: 'Cancelar',
            Icon: <CancelOutlined color="danger" />,
            Callback: () => cancelarCitaHandler(true)
          });
        }
    
        buttons.push({
          Name: 'Editar Contacto',
          Icon: <EditOutlined color='secondary' />,
          Callback: () => dispatch(ShowModal(ModalScreen.DETALLE_CONTACTO))
        });
    
        buttons.push({
          Name: 'Eliminar',
          Icon: <DeleteForeverOutlined color="danger" />,
          Callback: () => confirmEliminarCita()
        });
    
        return buttons;
      }

      async function confirmarCita() {
        setLoaderDetalle(true);
        let response = await confirmCitas_request(selectedCitaId);
        if (response.ok) {
          ReactGA.event({
            category: "Citas",
            action: "Cita_confirmada_exitosa",
            label: "cita confirmada exitosa", // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });

          Citas.refetch();
          dispatch(showSnackbar({
            show: true,
            text: 'Cita confirmada',
            type: 'success'
          }));
          dispatch(HideModal(ModalScreen.DETALLE_CITA));
        } else {
          ReactGA.event({
            category: "Citas",
            action: "Cita_confirmada_erronea",
            label: `${response.mensaje}`, // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          
          dispatch(showSnackbar({
            show: true,
            text: response.mensaje,
            type:'error'
          }));
          setLoaderDetalle(false);
        }
      }
    
      async function atenderCita() {
        setLoaderDetalle(true);

        let response = await atenderCitas_request(selectedCitaId);

        if (response.ok) {
          Citas.refetch();
          ReactGA.event({
            category: "Citas",
            action: "Cita_atendida_exitosa",
            label: "Cita atendida exitosa", // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        
          dispatch(showSnackbar({
            show: true,
            text: 'Cita atendida',
            type: 'success'
          }));
          setEstado('Atendida');
          setStatus()
          setLoaderDetalle(false);
          
        } else {
          ReactGA.event({
            category: "Citas",
            action: "Cita_atendida_erronea",
            label: `${response.mensaje}`, // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });

          dispatch(showSnackbar({
            show: true,
            text: response.mensaje,
            type: 'error'
          }));
          setLoaderDetalle(false);
        }
      }

      const selectDateHandler = async(date) =>{
        if(loadingSchedule) return;

        setLoadingSchedule(true);
        const dateString = date.format('YYYY-MM-DD');
        
        setFechaCita(dateString);
        const wDay = date.day();
        
        let data = new FormData();
    
        data.append("FechaInicio", dateString);
        data.append("FechaFin", dateString);
    
        let response = await getCitasFilter_request(4, data);
        
        if(response.ok){
          const horariosDisponibles = getHorariosDisponiblesByCitas(response.data, horariosConsulta[wDay]);
          
          setHorarios(getComboHorariosData(horariosDisponibles));
          setLoadingSchedule(false);
        }
        else{
          const horariosData = getComboHorariosData(horariosConsulta[wDay]);
          setHorarios(horariosData);
          setLoadingSchedule(false);
        }
      }

      const expedienteHandler = () => {
        dispatch(HideModal(ModalScreen.DETALLE_CITA));
        navigate("/app/ExpedientesNuevoDetalle", {
          state: {
            id: selectedContact.id,
            imagen: selectedContact.imagen,
            nombre: selectedContact.nombre,
          },
        });
      }
 
      const confirmEliminarCita = () => {
        modal.confirm({
          title: "¿Desea eliminar esta cita?",
          okText: "Confirmar",
          cancelText: "Cancelar",
          onOk: () => eliminarCita(),
          onCancel: () => {
            setDatosCita({
              nombre: "",
              tipo: "",
              estado: "",
              hora: "",
              fecha: "",
            });
            setEstatusModalEliminarCita(false);
          },
          open: EstatusModalEliminarCita,
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              <p>Paciente: {paciente}</p>
              <p>Fecha: {fecha}</p>
              <p>Hora: {horario}</p>
              <p>
                Tipo:{" "}
                {TiposCita[tipoCita]}
              </p>
              <p>Estado: {estado}</p>
            </div>
          ),
        });
      }

      const eliminarCita = async() => {
        setLoaderDetalle(true);
        let response = await deleteCitas_request(selectedCitaId);
        if (response.ok) {
          Citas.refetch();
          ReactGA.event({
            category: "Citas",
            action: "Cita_eliminada_exitosa",
            label: "cita eliminada exitosa", // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        
          dispatch(HideModal(ModalScreen.DETALLE_CITA));
          dispatch(showSnackbar({
            show: true,
            text: 'Cita eliminada',
            type: 'success'
          }));
        } else {
          ReactGA.event({
            category: "Citas",
            action: "Cita_eliminada_erronea",
            label: `${response.mensaje}`, // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          dispatch(showSnackbar({
            show: true,
            text: response.mensaje,
            type:'error'
          }));
          setLoaderDetalle(false);
        }
      }

      const cancelarCitaHandler = (cancel) => {
        setIsCanceling(cancel);
        setLoaderDetalle(cancel);
      }

      const cancelarCita = async() => {
        if(sending) return;
        setIsSending(true);
        
        if (!!motivoCancelacion) {
          let data = new FormData();
          data.append("Motivo", motivoCancelacion);
          let response = await cancelCitas_request(data, selectedCitaId);
          if (response.ok) {
            Citas.refetch();
            ReactGA.event({
              category: "Citas",
              action: "Cita_cancelada_exitosa",
              label: "Boton para cancelar cita", // optional
              value: 99, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            dispatch(showSnackbar({
              show: true,
              text: 'Cita cancelada',
              type: 'success'
            }));
            
            
            setIsSending(false);
            setIsCanceling(false);
            dispatch(HideModal(ModalScreen.DETALLE_CITA));
          } else {
            ReactGA.event({
              category: "Citas",
              action: "Cita_cancelada_erronea",
              label: `${response.mensaje}`, // optional
              value: 99, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            
            dispatch(showSnackbar({
              show: true,
              text: response.mensaje,
              type: 'error'
            }));
            setIsSending(false);
          }
        } else {
          setMensajeMotivoCancelacion("false");
          dispatch(showSnackbar({
            show: true,
            text: 'Debe escribir un motivo de cancelación',
            type: 'warning'
          }));
          setIsSending(false);
        }
      }

    return(
        <div>
          <div className="ModalDetalleCita">
            {loaderDetalle ? (
              <LoaderModals className={"ModalDetalleCita"} />
            ) : (
              <div className="GridItem2">
                <div className="all">

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      onError={(e) => { console.log(e); }}
                      size={75}
                      style={{
                        borderRadius: "5rem",
                        border: "1px solid #d5d5d5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                      }}
                      id="Img-cont"
                      src={
                        <Image src={
                          !!selectedContact.imagen
                            ? "https://" + selectedContact.imagen
                            : Imag
                        } />
                      }
                    />
                  </div>

                  <h3>{paciente}</h3>

                  <Row
                    style={{
                      width: "100%",
                      gap: "10px",
                      display: "flex",
                      marginTop: "50px",
                      justifyContent: "space-between",
                    }}
                  >
                    {inputsEditable ? (
                      <>
                        <Button
                          type="button"
                          style={{
                            background: "#148f9f",
                            border: "0",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                          onClick={() => expedienteHandler()}
                        >
                          Expediente
                          <EyeFilled />
                        </Button>
                        {(estado === "Confirmada" ||
                          estado === "Pendiente") ?
                          <Button
                            type="button"
                            style={{
                              background: "#148f9f",
                              border: "0",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              gap: ".5rem",
                            }}
                            onClick={() => {
                              dispatch(ShowModal(ModalScreen.FICHA_PACIENTE));
                            }}
                          >
                            Ficha
                            <EyeFilled />
                          </Button> : <div></div>
                        }

                        {(estado === "Confirmada" ||
                          estado === "Pendiente") ? <Button
                            style={{
                              background: "#148f9f",
                              border: "0",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              gap: ".5rem",
                            }}
                            onClick={() => {
                              setInputsEditable(false);
                            //   getHorarios(fecha);
                            }}
                          >
                          Editar Cita <Edit style={{ fontSize: 15 }} />
                        </Button> : <div></div>
                        }
                      </>

                    ) : (
                      <div style={{ display: "flex", width: "100%", alignContent: "center", justifyContent: "end" }}>

                        <Button
                          onClick={reprogramarCita}
                          style={{
                            background: "#148f9f",
                            border: "0",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                        >
                          Guardar
                        </Button>

                      </div>

                    )}
                  </Row>
                  <div className="Contenido-detalle">
                    <div style={{ position: "relative", width: '100%', height: '48px', marginTop: '10px' }}>
                      <div style={{ position: 'absolute', right: '0px' }}>
                      {inputsEditable &&
                        <PositionedMenu
                        setIdCita={setIdCita}
                        Cita={infoCita}
                        MenuOptions={getCitaMenuOptions(estado)}
                        extras={[
                        ]} />
                      }
                      </div>
                    </div>
                    {!inputsEditable && (
                      <Calendar
                        locale={locale}
                        value={dayjs(fecha)}
                        fullscreen={false}
                        onSelect={(date) => {
                          selectDateHandler(date);
                        }}
                      />
                    )}
                    {inputsEditable && (
                      <InputAntd
                        className="inputblue"
                        value={fecha}
                        label="Fecha:"
                        style={{ margin: "2% 0" }}
                        type="date"
                        disabled={true}
                      />
                    )}
                    <Row
                      style={{ justifyContent: "space-between", gap: "1rem" }}
                    >
                      <SelectAntd
                        label="Horario:"
                        placeholder="Elige un horario"
                        options={horarios}
                        setStateValue={setHorario}
                        setStateError={setComboHorarioCita}
                        textError="Debe seleccionar un horario"
                        value={horario}
                        valueError={ComboHorarioCita}
                        style={{ width: "100%", fontWeight: "normal" }}
                        styleDiv={{ width: "45%" }}
                        disabled={inputsEditable}
                      />

                      <SelectAntd
                        label="Tipo cita:"
                        placeholder="Elige un tipo de cita"
                        options={[
                          { value: "0", label: "Urgencia" },
                          { value: "1", label: "Primera Vez" },
                          { value: "2", label: "Seguimiento" },
                        ]}
                        style={{ width: "100%", fontWeight: "normal" }}
                        styleDiv={{ width: "45%" }}
                        setStateValue={setTipoCita}
                        value={tipoCita}
                        disabled={inputsEditable}
                      />
                    </Row>

                    <InputAntd
                      className="inputblue"
                      value={estado}
                      label="Estatus:"
                      style={{
                        color:
                          estado === "Confirmada"
                            ? "#EB9C37"
                            : estado === "Cancelada"
                              ? "red"
                              : estado === "Atendida"
                                ? "green"
                                : "grey",
                        margin: "2% 0",
                      }}
                      type="text"
                      readOnly={true}
                    />
                    {estado === "Cancelada" && (
                      <TextAreaAntd
                        label="Motivo de cancelación:"
                        // value={MotivoCancelacion}
                        placeholder="Escriba un motivo de cancelación"
                        style={{
                          marginBottom: ".5%",
                          fontFamily: "sans-serif",
                        }}
                        columns="30"
                        autoSize={true}
                        readOnly={true}
                      />
                    )}

                    <InputAntd
                      className="inputblue"
                      value={creadoPor}
                      label="Cita creada por:"
                      style={{ margin: "2% 0" }}
                      type="text"
                      readOnly={true}
                    />

                    <TextAreaAntd
                      label="Motivo de cita:"
                      value={motivo}
                      placeholder="Escriba un motivo de cita (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setMotivo}
                      readOnly={inputsEditable}
                    />

                    <TextAreaAntd
                      label="Comentarios o notas:"
                      value={comentario}
                      placeholder="Escriba un comentario o nota (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setComentario}
                      readOnly={inputsEditable}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {ModalEliminarCita}
          <Modal open={canceling} footer={[]} onCancel={()=>cancelarCitaHandler(false)}>
            <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "75px",
                      height: "75px",
                      borderRadius: "5rem",
                      overflow: "hidden",
                      border: "2px solid #d5d5d5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    id="Img-cont"
                  >
                    <Image
                      width={80}
                      src={
                        !!selectedContact.imagen
                          ? "https://" + selectedContact.imagen
                          : Imag
                      }
                    />
                  </div>
                </div>
                <h3 style={{ textAlign: "center" }}>{selectedContact.nombre}</h3>
                <TextAreaAntd
                  label="Motivo de cancelación:"
                  value={motivoCancelacion}
                  requiredICon={true}
                  placeholder="Escriba un motivo de cancelación"
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setMotivoCancelacion}
                  setStateError={setMensajeMotivoCancelacion}
                  valueError={MensajeMotivoCancelacion}
                  textError={["Debe escribir un motivo de cancelación"]}
                  vacio={false}
                />
                <Button
                  style={{
                    width: "100%",
                    background: "#148f9f",
                    borderColor: "#148f9f",
                    color: "white",
                    marginTop: "10px",
                  }}
                  size="sm"
                  onClick={(e) => {
                    cancelarCita();
                  }}
                >
                  Cancelar cita
                </Button>
            </div>
          </Modal>
        </div>
    )
}

export default DetallesCitaContent;