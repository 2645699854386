import React, { useRef, useEffect } from "react";
// ANTD
import { Avatar, Dropdown, Image } from "antd";
// MUI
import { MoreVert } from "@mui/icons-material";
import { ListItemDecorator } from "@mui/joy";
import CloseIcon from '@mui/icons-material/Close';
// Other Componentes
import MessageCard from "./message_card";
import BottomBarChat from "./bottom_bar_chat";
// Assets
import Imag from "../../Img/no-image.png";
// Styles
import "./styles/messages_view.scss"
import LoaderModals from "../GeneralComponents/Loader_modals";


function MessagesView({ messages, DatosContacto, chatContainerDivididoRef, loaderSendMessage, FilePreview, setFilePreview, setMessageToSend,
    setMessageToSendTemp, sendMessage, handleFileSelect, setChatOption, confirm, messageToSend, setOpen, setTypeMessage, setMessage,
    setShowViewMessages, loaderChat, setSelectedChat, OpenChatModal, setFromNewView }) {

    const lastMessageRef = useRef(null);

    let items = [
        DatosContacto.tipoUsuario !== 1 && {
            key: '1',
            label: <li onClick={() => {
                sessionStorage.setItem("idContactoExpediente", DatosContacto.id);
                sessionStorage.setItem("nombreContactoExpediente", DatosContacto.nombre);
                sessionStorage.setItem("imagenContactoExpediente", DatosContacto.imagen);
                window.open(`/#/app/ExpedientesNuevoDetalle`, "_blank");
            }}>Expediente</li>,
        },
        DatosContacto.tipoUsuario !== 1 && {
            key: '2',
            label: <li onClick={() => {
                setChatOption(2);
                setFromNewView(true);
                OpenChatModal(true);
            }}>Nueva receta</li>,
        },
        // {
        //     key: '3',
        //     label: <li onClick={() => {
        //         confirm();
        //     }}>Eliminar chat</li>,
        // },
    ];

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "instant" });
        }
    }, [messages]);

    const renderMessages = () => {
        let msgObjects = [];
        if (!loaderChat || messages.length > 0) {
            messages.map((chats, index) => (
                msgObjects.push(<MessageCard key={chats.id} message={chats} DatosContacto={DatosContacto} />)
            ));
        }
        else {
            return <></>
        }

        return msgObjects;
    }

    return (
        <>
            <div className="chatBarView">
                <div style={{ display: "flex", alignItems: "center", gap: "2%", width: "95%" }}>
                    <Avatar size={55} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        className="GeneralNoImage"
                        src={
                            <Image
                                style={{ background: "#d7d7d7" }}
                                src={
                                    DatosContacto.imagen
                                        ? "https://" + DatosContacto.imagen
                                        : Imag
                                }
                            />
                        } />
                    <div className="">
                        <span style={{ color: "white", fontWeight: "500", wordBreak: "break-all" }}>{DatosContacto.nombre}</span>
                    </div>

                    <Dropdown
                        menu={{ items, }}
                        trigger={["click"]}
                    >
                        <ListItemDecorator>
                            <MoreVert style={{ cursor: "pointer" }} />
                        </ListItemDecorator>

                    </Dropdown>

                </div>

                <CloseIcon
                    onClick={() => { setShowViewMessages(false); setSelectedChat(null); }} className="opciones" style={{ cursor: "pointer", fontSize: "20px" }}
                />

            </div>
            <div id="Chatmessage" className="Chatmessage" ref={chatContainerDivididoRef}>
                {loaderChat ? (
                    <LoaderModals className="loaderMessageView" />
                ) :
                    <>
                        {renderMessages()}
                        <div ref={lastMessageRef} />
                    </>
                }
            </div>
            <BottomBarChat
                FilePreview={FilePreview}
                loaderSendMessage={loaderSendMessage}
                setFilePreview={setFilePreview}
                handleFileSelect={handleFileSelect}
                messageToSend={messageToSend}
                sendMessage={sendMessage}
                setMessageToSend={setMessageToSend}
                setMessageToSendTemp={setMessageToSendTemp}
                setOpen={setOpen}
                setTypeMessage={setTypeMessage}
                setMessage={setMessage}
            />
        </>
    );

}

export default MessagesView;