import * as React from "react";
import { useState } from "react";

//importando Iconos
import Iconos from "../IconosDeSitio";

import IconButton from "@mui/joy/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

//Drowdown mui
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/joy/Menu";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import {
  Add,
  CancelRounded,
  CheckCircleOutline,
  Edit,
} from "@mui/icons-material";
import { Dropdown } from "antd";
import ReactGA from "react-ga4";
import { UserSwitchOutlined } from "@ant-design/icons";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import { useSelector } from "react-redux";

export default function MenuPerfil({
  OpenConfig,
  consulta,
  onLogout,
}) {

  const navigate = useNavigate();
  const infoUser = useSelector((state) => state.userInfo.usuario);
  // console.log(data.tipo);
  const [anchorElPerfil, setAnchorElPerfil] = React.useState(null);
  const openPerfil = Boolean(anchorElPerfil);
  const handleClickPerfil = (event) => {
    // console.log("ABRE");
    setAnchorElPerfil(event.currentTarget);
  };

  const handleClosePerfil = (event) => {
    // console.log("CIERRA");
    setAnchorElPerfil(null);
  };

  // let items = [];

  let items = infoUser.Tipo === 4 ? [
    {
      key: "1",
      label: (
        <li
          style={{
            listStyle: "none",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            color: "#464646",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("/app/perfil");
            } else {
              navigate("/app/perfil", { replace: true });
            }
          }}
        >
          <ListItemDecorator sx={{ color: "inherit" }}>
            <PersonOutlinedIcon color="disabled" />
          </ListItemDecorator>
          Perfil
        </li>
      ),
    },
    // (infoUser.Paquete !== "0" && infoUser.PaqueteStatus === 1) &&
     {
      key: "3",
      label: (
        <li
          style={{
            listStyle: "none",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            color: "#464646",
            cursor: "pointer",
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          onClick={OpenConfig}
        >
          <ListItemDecorator sx={{ color: "inherit" }}>
            {/* <PowerSettingsNewIcon color='disabled' /> */}
            <SettingsOutlinedIcon color="disabled" />
          </ListItemDecorator>
          Formatos
        </li>
      ),
    },
    {
      key: "4",
      label: (
        <li
          style={{
            listStyle: "none",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            color: "#464646",
            cursor: "pointer",
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          onClick={() => {
            if (consulta !== undefined) {
              ReactGA.event({
                category: "Opciones",
                action: "Modulo ayuda",
                label: "Boton para acceder al modulo de ayuda", // optional
                value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });
              consulta("/app/Ayuda");
            } else {
              ReactGA.event({
                category: "Opciones",
                action: "Modulo ayuda",
                label: "Boton para acceder al modulo de ayuda", // optional
                value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });
              navigate("/app/Ayuda", { replace: true });
            }
          }}
        >
          <ListItemDecorator sx={{ color: "inherit" }}>
            {/* <PowerSettingsNewIcon color='disabled' /> */}
            <HelpOutlineIcon color="disabled" />
          </ListItemDecorator>
          Ayuda
        </li>
      ),
    },
    {
      key: "2",
      label: (
        <li
          style={{
            listStyle: "none",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            color: "#464646",
            cursor: "pointer",
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("", onLogout);
            } else {
              onLogout();
            }
          }}
        >
          <ListItemDecorator sx={{ color: "inherit" }}>
            <PowerSettingsNewIcon color="disabled" />
          </ListItemDecorator>
          Cerrar sesión
        </li>
      ),
    },
  ] :
    [
      {
        key: "1",
        label: (
          <li
            style={{
              listStyle: "none",
              textDecoration: "none",
              fontSize: "15px",
              fontWeight: "500",
              color: "#464646",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
            onClick={() => {
              if (consulta !== undefined) {
                consulta("/app/perfil");
              } else {
                navigate("/app/perfil", { replace: true });
              }
            }}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              <PersonOutlinedIcon color="disabled" />
            </ListItemDecorator>
            Perfil
          </li>
        ),
      },
      // (infoUser.Paquete !== "0" && infoUser.PaqueteStatus === 1) &&
       {
        key: "3",
        label: (
          <li
            style={{
              listStyle: "none",
              textDecoration: "none",
              fontSize: "15px",
              fontWeight: "500",
              color: "#464646",
              cursor: "pointer",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
            onClick={OpenConfig}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              {/* <PowerSettingsNewIcon color='disabled' /> */}
              <SettingsOutlinedIcon color="disabled" />
            </ListItemDecorator>
            Formatos
          </li>
        ),
      },
      {
        key: "4",
        label: (
          <li
            style={{
              listStyle: "none",
              textDecoration: "none",
              fontSize: "15px",
              fontWeight: "500",
              color: "#464646",
              cursor: "pointer",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
            onClick={() => {
              if (consulta !== undefined) {
                ReactGA.event({
                  category: "Opciones",
                  action: "Modulo ayuda",
                  label: "Boton para acceder al modulo de ayuda", // optional
                  value: 99, // optional, must be a number
                  nonInteraction: true, // optional, true/false
                  transport: "xhr", // optional, beacon/xhr/image
                });
                consulta("/app/Ayuda");
              } else {
                ReactGA.event({
                  category: "Opciones",
                  action: "Modulo ayuda",
                  label: "Boton para acceder al modulo de ayuda", // optional
                  value: 99, // optional, must be a number
                  nonInteraction: true, // optional, true/false
                  transport: "xhr", // optional, beacon/xhr/image
                });
                navigate("/app/Ayuda", { replace: true });
              }
            }}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              {/* <PowerSettingsNewIcon color='disabled' /> */}
              <HelpOutlineIcon color="disabled" />
            </ListItemDecorator>
            Ayuda
          </li>
        ),
      },
      // (infoUser.Paquete !== "0" && infoUser.PaqueteStatus === 1) && 
      {
        key: "5",
        label: (
          <li
            style={{
              listStyle: "none",
              textDecoration: "none",
              fontSize: "15px",
              fontWeight: "500",
              color: "#464646",
              cursor: "pointer",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
            onClick={() => {
              if (consulta !== undefined) {
                consulta("/app/MetodosDePago");
              } else {
                navigate("/app/MetodosDePago", { replace: true });
              }
            }}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              <CardMembershipIcon color="disabled" />
            </ListItemDecorator>
            Pagos
          </li>
        ),
      },
      {
        key: "2",
        label: (
          <li
            style={{
              listStyle: "none",
              textDecoration: "none",
              fontSize: "15px",
              fontWeight: "500",
              color: "#464646",
              cursor: "pointer",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
            onClick={() => {
              if (consulta !== undefined) {
                consulta("", onLogout);
              } else {
                onLogout();
              }
            }}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              <PowerSettingsNewIcon color="disabled" />
            </ListItemDecorator>
            Cerrar sesión
          </li>
        ),
      },
    ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <img
        onClick={(e) => {
          e.preventDefault();
        }}
        style={{
          width: "40px",
          height: "40px",
          overflow: "hidden",
          border: "2px solid #727272",
          borderRadius: "5rem",
          objectFit: "cover",
          background: "#d7d7d7",
          cursor: "pointer",
          position: "relative",
        }}
        src={infoUser.Imagen_Url != "" ? "https://" + infoUser.Imagen_Url : Iconos[0]}
      />
    </Dropdown>
  );
}
