import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { db } from "../storage/db";

import { chats_request, solicitudChat_request } from "../services/chats_services";

export const StatusChat = Object.freeze({
    Loading: 0,
    Loaded: 1
});

const useChatManager = () => {
    const [isLoadingChats, setIsLoadingChats] = useState(true);

    useEffect(()=>{
        InitChatData();
    }, []);

    const getAllChats = async() => {
        let response = await chats_request();
        if (response.ok) {
          const chats = response.data.map(chat=> {
            return { ...chat, status_chat: StatusChat.Loading }
          })
          loadMessages(chats);
          return chats;
        }
        return [];
      }

    const loadMessages = async(chats) => {
      let limitChats = 10;

      Promise.all(chats.map(async (chat, index)=>{
        if(index < limitChats){
          let timestamp = chat.lastSync?chat.lastSync : dayjs().subtract(1, 'year').valueOf();
          
          await LoadMessagesFromConversation(chat.Referencia, timestamp);
        }
      }));
    }

    const LoadMessagesFromConversation = async(referencia, timestamp) => {
      let mensajes = await solicitudChat_request(referencia, timestamp);
          
      if (mensajes.ok) {
          await db.mensajes.bulkPut(mensajes.data.Mensajes);
          
          const updateTimeStamp = dayjs().valueOf();
          await db.conversations.update(referencia, { status_chat: StatusChat.Loaded, lastSync: updateTimeStamp})
            .catch((error) => {
              console.error("Error al actualizar el registro:", error);
            });
      }
    }

    const InitChatData = async() => {
      const chats = await getAllChats();
        
      await db.conversations.bulkPut(chats);
        
      setIsLoadingChats(false);
    }

    const InitChatMessages = async(messages) => {
        await db.mensajes.add(messages)
    }

    const GetConversations = async()=>{
        const conversations = await db.conversations.toArray();
        return conversations;
    }

    const AddConversation = async(conversationData) => {
        await db.conversations.add({...conversationData});
    }

    const DeleteConversation = async(referenciaChat) => {
      await db.mensajes.where("Referencia").equals(referenciaChat).delete();
      await db.conversations.delete(referenciaChat);
    }

    const AddMessage = async(newMessage) => {
      await db.mensajes.add(newMessage);
      let timestamp = dayjs().valueOf();
      
      const conversation = await db.conversations.get(newMessage.Referencia);
      if(conversation){
        await db.conversations.update(newMessage.Referencia,{message: newMessage.message, fecha: newMessage.fecha, TipoMensaje: newMessage.TipoMensaje, lastSync: timestamp});
      }
      else{
        await db.conversations.put({message: newMessage.message, fecha: newMessage.fecha, TipoMensaje: newMessage.TipoMensaje, Referencia: newMessage.Referencia, lastSync: timestamp})
      }
    }

    const GetConversation = async(contactoId) => {
      const conversation = await db.conversations.get({IdContacto: contactoId});
      
      return conversation;
    }

    return {
        InitChatData, InitChatMessages, GetConversations, AddConversation, AddMessage, DeleteConversation, GetConversation,
        LoadMessagesFromConversation,
        isLoadingChats
    };
}

export default useChatManager;