import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getBadgeNotificationsFirestore, getNotificationsFirestore, getTokenFirebase } from "./firebase";
import ReactGA from "react-ga4";
import { useDispatch } from 'react-redux';

import { Loader } from "./componentes/GeneralComponents/Loader";

import "../src/componentes/comp-styles/Navbar.scss";
import "./componentes/comp-styles/Sidebar.scss";
import "../src/componentes/comp-styles/Sitio.scss";

import { infoUser_request } from "./services/perfil_services";
import { getDoctorsLink_request } from "./services/asistentes_services";

import { infoUser } from "./redux/userSlice";
import { doctorsToAssistant } from "./redux/doctorsToAssistant";
import { infoDoctorLog } from "./redux/infoDoctorLog";
import useContactosData from "./hooks/Query/useContactosData";
import useConfigCitasData from "./hooks/Query/useConfigCitasData";
import useCitasData from "./hooks/Query/useCitasData";
import useExpedientesData from "./hooks/Query/useExpedientesData";
import useChatManager from "./hooks/useChatManager";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Sitio() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // tQuery Init
  const contactos = useContactosData();
  const configCitas = useConfigCitasData();
  const citas = useCitasData();
  const expedientes = useExpedientesData();

  const chatManager = useChatManager();

  const [loader, setLoader] = useState(false);

  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  async function getInfoUser() {
    let response = await infoUser_request();

    if (response["ok"]) {
      dispatch(infoUser(response.data));
      let info = response.data.Usuario;
      console.log(info)

      if (response.data.Usuario.Tipo === 1) {

        // if (response.data.Usuario.Paquete === "0" && response.data.Usuario.PaqueteStatus === 1) {
        //   navigate("/app/licencia", { replace: true });
        // } else if (info.Paquete === "1" && info.PaqueteStatus > 1) {
        //   navigate("/app/licencia", { replace: true });
        // }
      } else {
        let loginAsistente = localStorage.getItem("loginAsistente");

        localStorage.setItem("paquete", response.data.Usuario.Paquete);
        localStorage.setItem("paqueteStatus", response.data.Usuario.PaqueteStatus);
        if (loginAsistente === "0") {
          navigate("/selectMedico", {
            replace: true,
            state: {
              logged: true
            },
          });
        } else {
          getDoctorsLink();

          // TODO revisar
          // if (response.data.Usuario.Paquete === "0" && response.data.Usuario.PaqueteStatus === 1) {
          //   navigate("/app/licencia", { replace: true });
          // } else if (info.Paquete === "1" && info.PaqueteStatus > 1) {
          //   navigate("/app/licencia", { replace: true });
          // }
        }
      }

      // InitChatData();
      getNotisFirestore(info.id);
    } else {

      if (response.codigo === 401) {
        sessionStorage.removeItem("infoUser");
        localStorage.removeItem("permisos");
        localStorage.setItem("loginAsistente", 0);
        localStorage.removeItem("token");
        localStorage.removeItem("tipoUsuario");
        localStorage.removeItem("datosMedico");
        localStorage.removeItem("logged");
        navigate("/login", {
          replace: true,
          state: {
            logged: false,
          },
        });
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    }
    return;
  }

  async function getDoctorsLink() {
    let response = await getDoctorsLink_request();
    if (response.ok) {
      // console.log(response.data);
      dispatch(doctorsToAssistant({ doctors: response.data }));
      let logged = 0;
      response.data.forEach(element => {

        if (element.Logged === 1) {
          if (document.domain === "localhost") {
            console.log(element);
          }
          logged = 1;
          dispatch(infoDoctorLog(element));
        }
      });
      // console.log(logged);
      if (logged === 0) {
        localStorage.setItem("loginAsistente", 0);
        navigate("/selectMedico", {
          replace: true,
          state: {
            logged: true
          },
        });
      }

    } else {
      dispatch(doctorsToAssistant({ doctors: [] }));
    }
  }

  async function getNotisFirestore(idUser) {
    await getBadgeNotificationsFirestore(dispatch, idUser);
    await getNotificationsFirestore(dispatch, idUser);
  }

  useEffect(() => {
    getInfoUser();
    if (navigator.userAgent.match(/Windows/i)
      || navigator.userAgent.match(/macintosh/i)) {
      // console.log(Notification.permission);
      if (Notification.permission === "default" || Notification.permission === "denied") {
        getTokenFirebase();
      }

      if ('showTrigger' in Notification.prototype) {
        /* Se admiten los activadores de notificación */
        // console.log("Se admiten los activadores de notificación");
      }
    }

  }, []);

  return (
    <Stack>
      {loader ? (<Loader />) : (
        <>
          <div
            className="conteOutlet"
          >
            <Outlet />
          </div>
        </>
      )
      }
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack >
  );
}

export default Sitio;
