import React, { useEffect } from "react";
import Waveform from "./waveform";
import { Space } from "antd";
import { DoneAll, PictureAsPdf } from "@mui/icons-material";
import PreviewImage from "../GeneralComponents/previewImage";
import mime from 'mime';
import "./styles/message_card.scss"


function MessageCard({ message, DatosContacto }) {

    function videoOrImage(fileUrl, fecha) {
        const mimeType = mime.getType(fileUrl);
        return mimeType.substring(0, mimeType.lastIndexOf("/"));
    }

    return (
        <div
            key={message.id}
            className={(message.IdUsuario !== DatosContacto.idUsuario) ? "mensaje2 left" : "mensaje"}>
            <div className="cuerpo">
                {message.TipoMensaje.toString() === "0" && (
                    <div>
                        <Space style={{ wordBreak: "break-word" }}>
                            {message.message}
                        </Space>
                        <div className="tiempo">
                            <p style={{ fontSize: "12px" }}>{message.fecha}</p>
                            {(message.IdUsuario !== DatosContacto.idUsuario) && (<DoneAll fontSize="12px" />)}
                        </div>
                    </div>
                )}

                {message.TipoMensaje.toString() === "1" && (
                    <div>
                        <a href={message.MultimediaUrl.includes("https://") ? message.MultimediaUrl : `https://${message.MultimediaUrl}`} target="_blank" rel="noopener noreferrer" style={{ listStyle: "none", color: "white" }}>
                            <div style={{ display: "flex", textAlign: "center", gap: "5px" }}><PictureAsPdf size={35} /> {!!message.NombreMultimedia ? message.NombreMultimedia : "Documento PDF"}</div>
                        </a>
                        <div className="tiempo">
                            <p style={{ fontSize: "12px" }}>{message.fecha}</p>
                            {(message.IdUsuario !== DatosContacto.idUsuario) && (<DoneAll fontSize="12px" />)}
                        </div>
                    </div>
                )}

                {message.TipoMensaje.toString() === "2" && (
                    <div className="archivo">
                        {videoOrImage(message.MultimediaUrl, message.created_at) === "image" ? (
                            <div>
                                <PreviewImage
                                    urlImage={message.MultimediaUrl.includes("https://") ? message.MultimediaUrl : `https://${message.MultimediaUrl}`}
                                    urlImageSecond={message.MultimediaUrl}
                                    style={{ width: "200px", height: "150px" }}
                                    imageName={`YGIAChat_${new Date().toISOString().replace('.', ':')}.${message.MultimediaUrl.substring(message.MultimediaUrl.toString().length, message.MultimediaUrl.toString().length - 3)}`}
                                />
                            </div>
                        ) : (
                            <video controls>
                                <source src={message.MultimediaUrl} type="video/mp4" />
                            </video>
                        )}

                        <div className="tiempo">
                            <p style={{ fontSize: "12px" }}>{message.fecha}</p>
                            {(message.IdUsuario !== DatosContacto.idUsuario) && (<DoneAll fontSize="12px" />)}
                        </div>
                    </div>

                )}

                {message.TipoMensaje.toString() === "3" && (
                    <div>
                        <Waveform audioUrl={message.MultimediaUrl} isMe={(message.IdUsuario === DatosContacto.idUsuario) ? false : true} />
                        <div className="tiempo">
                            <p style={{ fontSize: "10px", fontStyle: 'italic' }}>{message.fecha}</p>
                            {(message.IdUsuario !== DatosContacto.idUsuario) && (<DoneAll fontSize="12px" />)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

}


export default MessageCard;