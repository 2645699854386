/*Importando Archivos*/
import { BrowserRouter, HashRouter, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { PrivateRoute } from './PrivateRoute';

// Importando componentes
import Login from '../componentes/Login/Login';
import Splash from "../componentes/GeneralComponents/Splash"
import Error404 from "../componentes/GeneralComponents/404ErrorPagina"

//Importando paguinas
import Chats from '../pages/chats';
import Recuperacion from '../pages/Recuperacion';
import Registro from '../pages/Registro';
import SelectMedico from '../pages/SelectMedico';
import Citas from "../pages/Citas";
import Contactos from "../pages/Contactos";
import Consultas from '../pages/Consultas';
import Recetas from "../pages/Recetas";
import ExpedientesNuevo from "../pages/ExpedientesNuevo";
import ExpedientesNuevoDetalle from "../componentes/NuevoExpediente/ExpedienteNuevoDetalle"
import Estudios from "../pages/Estudios";
import Vinculacion from '../pages/Vinculacion';
import Sitio from "../Sitio";
import EnContruccion from "../pages/EnContruccion";
import Perfil from "../componentes/Perfil/Perfil";
import Facturacion from "../pages/Facturacion";
import Asistentes from "../pages/Asistentes";
import ConfCitas from "../componentes/Citas/ConfiguracionCitas";
import MetodosDePago from "../pages/MetodosDePago";
import PuntoDeVenta from "../pages/PuntoDeVenta";
import IngYEgre from "../pages/Ingresosyegresos";
import Reportes from "../pages/Reportes";
import Anuncios from "../pages/Anuncios";
import NuevoExpediente from "../componentes/NuevoExpediente/addExpediente";
import AddConsulta from "../componentes/Consultas/addConsulta";
import Licencia from "../pages/Licencia"
import EditConsulta from "../componentes/Consultas/editConsulta";
import Ayuda from "../pages/Ayuda";
import Tutoriales from "../pages/Tutoriales";
import Ordenes from "../pages/Ordenes";
import HomeView from "../pages/HomeView";

function AppRouter() {
  return (
    <HashRouter>
      <Routes>
        {/* Rutas de login */}
        <Route exact path='/' element={<Index />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='*' element={<NotFound />} />
        <Route exact path='/recuperacion' element={<Recuperacion />} />
        <Route exact path='/registrarme' element={<Registro />} />
        <Route exact path='/selectMedico' element={<SelectMedico />} />
        {/* Rutas de sitio */}
        <Route path='/app' element={<Sitio />}>
          <Route path='/app/licencia' element={<Licencia />} />
          <Route path='/app/inicio' element={<PrivateRoute> < HomeView /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/citas' element={<PrivateRoute> < Citas /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/chats' element={<PrivateRoute>< Chats /></PrivateRoute>} />
          <Route path='/app/Recetas' element={<PrivateRoute>< Recetas /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/Ordenes' element={<PrivateRoute>< Ordenes /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/estudios' element={<PrivateRoute>< Estudios /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/vinculacion' element={<PrivateRoute>< Vinculacion /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/consultas' element={<PrivateRoute><Consultas /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/NuevaConsulta" element={<PrivateRoute><AddConsulta /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/ModificarConsulta" element={<PrivateRoute><EditConsulta /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/enconstruccion' element={<PrivateRoute><EnContruccion /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/perfil' element={<PrivateRoute><Perfil /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/Contactos" element={<PrivateRoute><Contactos /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/Facturacion" element={<PrivateRoute><Facturacion /></PrivateRoute>} />
          <Route path="/app/Asistentes" element={<PrivateRoute><Asistentes /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/CitasConfig" element={<PrivateRoute><ConfCitas /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/MetodosDePago" element={<PrivateRoute><MetodosDePago /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/PuntoDeVenta" element={<PrivateRoute><PuntoDeVenta /></PrivateRoute>} /> {/* Listo */}
          <Route path='/app/IngresosyEgresos' element={<PrivateRoute><IngYEgre /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/Reportes" element={<PrivateRoute><Reportes /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/Anuncios" element={<PrivateRoute><Anuncios /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/ExpedientesNuevo" element={<PrivateRoute><ExpedientesNuevo /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/ExpedientesNuevoDetalle" element={<PrivateRoute><ExpedientesNuevoDetalle /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/NuevoExpediente" element={<PrivateRoute><NuevoExpediente /></PrivateRoute>} /> {/* Listo */}
          <Route path="/app/Ayuda" element={<PrivateRoute> <Ayuda /> </PrivateRoute>} /> {/* Listo */}
          <Route path="/app/Tutoriales" element={<PrivateRoute> <Tutoriales /> </PrivateRoute>} /> {/* Listo */}

        </Route>

      </Routes>
    </HashRouter>
    //     <BrowserRouter basename={process.env.PUBLIC_URL}>
    //     <Routes>
    //       {/* Rutas de login */}
    //       <Route exact path='/'element={<Index/>}/>
    //       <Route exact path='/login'element={<Login/>}/>
    //       <Route exact path='*'element={<NotFound/>}/>
    //       {/* <Route exact path='/login'element={<Login/>}/> */}
    //       <Route exact path='/recuperacion'element={<Recuperacion/>}/>
    //       <Route exact path='/registrarme'element={<Registro/>}/>
    //       {/* Rutas de sitio */}
    //       <Route path='/app' element={ <Sitio/>}>
    //             <Route  path='/app/citas' element={ < Citas />}/>
    //             {/* <Route  path='/app/citas' element={<PrivateRoute> < Citas /></PrivateRoute>}/> */}
    //             <Route  path='/app/recetas' element={< Recetas />}/>
    //             <Route  path='/app/expedientes' element={< Expedientes />}/>
    //             <Route  path='/app/estudios' element={< Estudios />}/>
    //             <Route  path='/app/vinculacion' element={< Vinculacion />}/>
    //             <Route path='/app/consultas' element={<Consultas/>}/>
    //             <Route path='/app/enconstruccion' element={<EnContruccion/>}/>
    //             <Route path='/app/perfil' element={<Perfil/>}/>
    //             <Route path="/app/NotasMedicas" element={<NotasMedicas/>}/>
    //             <Route path="/app/Contactos" element={<Contactos/>}/>
    //             <Route path="/app/ServiciosAuxiliaresDeDiagnostico" element={<SeviciosAxiliares/>}/>
    //             <Route path="/app/NotasDeDefuncion" element={<NotasDeDefuncion/>}/>
    //             <Route path="/app/HistorialClinico" element={<HistorialClinico/>}/>


    //        </Route>

    //     </Routes>
    //  </BrowserRouter>
  )
}

function NotFound() {
  return <><Error404 /></>;
}

function Index() {
  // localStorage.removeItem("token");

  const accessToken = localStorage.getItem("token");
  const loginAsistente = localStorage.getItem("loginAsistente");
  const tipoUsuario = localStorage.getItem("tipoUsuario");
  const paquete = localStorage.getItem("paquete");
  const paqueteStatus = localStorage.getItem("paqueteStatus");
  const navigate = useNavigate();

  useEffect(() => {
    // alert("index rputer");
    // console.log(paquete);
    // console.log(paqueteStatus);
    setTimeout(() => {
      // 👇 Redirects to about page, note the `replace: true`
      if (accessToken !== "" && accessToken !== null) {
        if (loginAsistente === "0" && tipoUsuario === "4") {
          navigate("/selectMedico", {
            replace: true,
            state: {
              logged: true
            },
          });
        } else {
          navigate("/app/inicio", { replace: true });
        }
        // if (paquete === "0" && paqueteStatus === "1") {
        //   navigate("/app/licencia", { replace: true });
        // } else if (paquete === "1" && paqueteStatus !== "1") {
        //   navigate("/app/licencia", { replace: true });
        // } else {
        //   if (loginAsistente === "0" && tipoUsuario === "4") {
        //     navigate("/selectMedico", {
        //       replace: true,
        //       state: {
        //         logged: true
        //       },
        //     });
        //   } else {
        //     navigate("/app/inicio", { replace: true });
        //   }
        // }
      } else {
        navigate('/login', { replace: true });
      }

    }, 3000);
  }, []);

  return <div>
    <Splash />
  </div>;
}

export default AppRouter;

