import React from "react";
import { useNavigate } from "react-router-dom";
import AddList from "../../Img/Iconos/Agregar.svg"
import "../comp-styles/ModListDeContactos.scss"
import Iconos from "../IconosDeSitio"

// VARIABLE "funcionExtra[0]"
// 1 = Estudios
// 2 = Recetas
// 3 = Punto de venta
// 4 = Consultas
// 5 = Chats
// 6 = Ordenes

export default function ModListDeContactos({
  Lista,
  CloseModal,
  OpenModal,
  funcionExtra,
  style,
  setShowViewMessages,
}) {
  // FUTUROS PARAMETROS
  // lista,
  // openModal,
  // CloseModal,
  // setId,

  const navigate = useNavigate();
  return (
    <div className="contCardModal" style={style}>
      {Lista.map((contacto, index) => (
        <div className="ContenedorListaMod" key={index} onClick={() => {
          if (CloseModal !== undefined) {
            CloseModal(false);
          }

          if (OpenModal !== undefined) {
            OpenModal(true);
          } else {
            if (setShowViewMessages !== null) {
              setShowViewMessages(true);
            }
          }
          // 1 = Estudios
          if (funcionExtra[0] === 1) {
            funcionExtra[1](contacto.id);
            funcionExtra[2]({ nombre: contacto.usuario, imagen: contacto.imagen });
            funcionExtra[3](false);
          }
          // 2 = Recetas
          if (funcionExtra[0] === 2) {
            funcionExtra[1]({ id: contacto.id, nombre: contacto.usuario });
          }
          // 3 = Punto de venta
          if (funcionExtra[0] === 3) {
            funcionExtra[1]({ nombre: contacto.usuario, imagen: contacto.imagen, idContacto: contacto.id });
          }
          // 4 = Consultas
          if (funcionExtra[0] === 4) {
            funcionExtra[1]({ nombre: contacto.usuario, imagen: contacto.imagen, id: contacto.id });
            funcionExtra[2](true);
          }
          // 5 = Chats
          if (funcionExtra[0] === 5) {
            funcionExtra[1]({ nombre: contacto.usuario, imagen: contacto.imagen, id: contacto.id, idUsuario: contacto.idUsuario });
            funcionExtra[2](contacto.id);
          }
          // 6 = Ordenes
          if (funcionExtra[0] === 6) {
            funcionExtra[1]({ nombre: contacto.usuario, imagen: contacto.imagen, id: contacto.id });
            funcionExtra[2](1);
          }
          // setId(contacto.id); 
          // OpenModal(true);
        }}>

          <div
            style={{
              width: "90%", display: "flex", alignItems: "center", gap: "1%",
            }}
          >
            <img className="ImagPerfilListaMod" src={!!contacto.imagen ? "https://" + contacto.imagen : Iconos[0]} alt="" />
            {/* <b style={{marginLeft:"3px"}}>{contacto.nombre}</b> */}
            <b style={{ textAlign: "left", width: "80%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{contacto.usuario}</b>
          </div>
        </div>

      ))}
    </div>
  );
}
